import React from 'react'
import Button from 'react-bootstrap/esm/Button'
import { useDrag, useDrop } from 'react-dnd'
import { BsArrowsMove } from 'react-icons/bs'
import { Link } from 'react-router-dom'
const TYPE_ACCEPT = 'tr'

export const LessonRow = ({ index, lesson, moveRow, lessonEdit, handleLessonRemove }) => {
	const dropRef = React.useRef(null)
	const dragRef = React.useRef(null)

	const [, drop] = useDrop({
		accept: TYPE_ACCEPT,
		hover(item, monitor) {
			if (!dropRef.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = dropRef.current.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveRow(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag, preview] = useDrag({
		item: { index },
		type: TYPE_ACCEPT,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const opacity = isDragging ? 0 : 1

	preview(drop(dropRef))
	drag(dragRef)

	return (
		<tr ref={dropRef} style={{ opacity }}>
			<td ref={dragRef} className="text-center">
				<BsArrowsMove />
			</td>
			<td>{index + 1}</td>
			<td>{lesson.type}</td>
			<td>{lesson.title}</td>
			{/* <td className="ellipsis">{module.description}</td> */}
			<td className="module-list-actions">
				<Link to={`./lecciones/${lesson.id}/bloques`}>
					<Button className="module-btn module-btn-manage" variant="primary" size="sm">
						Administrar Bloques
					</Button>
				</Link>
				<Button
					className="module-btn module-btn-edit"
					variant="success"
					size="sm"
					onClick={() => {
						lessonEdit(lesson.id)
					}}
				>
					Editar
				</Button>
				<Button
					className="module-btn module-btn-delete"
					variant="danger"
					size="sm"
					onClick={() => {
						handleLessonRemove(lesson)
					}}
				>
					Eliminar
				</Button>
			</td>
		</tr>
	)
}
