import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import UserLoadingRow from '../../components/shared/UserLoadingRow'
import { UserContext } from '../../context/shared/UserContext'

export default function BasePage({ children, requeriments }) {
	const { user, userBusy } = useContext(UserContext)
	const history = useHistory()

	useEffect(() => {
		if (requeriments.includes('user')) {
			if (!userBusy && user === false) {
				history.push('/')
			}
		}
	}, [user])

	return <>{userBusy ? <UserLoadingRow /> : children}</>
}
