import axios from 'axios'
import { lmsPlataformURL, platformBearerToken } from '../../classes/Environment'

export const coursePost = (userToken, data) => {
	return axios
		.post(
			`${lmsPlataformURL}course`,
			{ data },
			{
				headers: {
					Authorization: `Bearer ${platformBearerToken}`,
					DiaToken: userToken,
				},
			}
		)
		.then((res) => {
			return res
		})
		.catch((err) => {
			throw new Error(err.response.data.error_txt)
		})
}
export const coursePut = (userToken, data) => {
	return axios
		.put(
			`${lmsPlataformURL}course`,
			{ data },
			{
				headers: {
					Authorization: `Bearer ${platformBearerToken}`,
					DiaToken: userToken,
				},
			}
		)
		.then((res) => {
			return res
		})
		.catch((err) => {
			throw new Error(err.response.data.error_txt)
		})
}
export const courseDelete = (userToken, data) => {
	return axios
		.delete(`${lmsPlataformURL}course/${data.id}`, {
			headers: {
				Authorization: `Bearer ${platformBearerToken}`,
				DiaToken: userToken,
			},
		})
		.then((res) => {
			return res
		})
		.catch((err) => {
			throw new Error(err.response.data.error_txt)
		})
}

// Modules
// Modules
// Modules
// Modules

export const modulePost = (userToken, courseId, module) => {
	return axios
		.post(
			`${lmsPlataformURL}course/${courseId}/module`,
			{ data: module },
			{
				headers: {
					Authorization: `Bearer ${platformBearerToken}`,
					DiaToken: userToken,
				},
			}
		)
		.then((res) => {
			return res
		})
		.catch((err) => {
			throw new Error(err.response.data.error_txt)
		})
}
export const modulePut = (userToken, courseId, module) => {
	return axios
		.put(
			`${lmsPlataformURL}course/${courseId}/module`,
			{ data: module },
			{
				headers: {
					Authorization: `Bearer ${platformBearerToken}`,
					DiaToken: userToken,
				},
			}
		)
		.then((res) => {
			return res
		})
		.catch((err) => {
			throw new Error(err.response.data.error_txt)
		})
}
export const moduleDelete = (userToken, courseId, module) => {
	return axios
		.delete(`${lmsPlataformURL}course/${courseId}/module/${module.id}`, {
			headers: {
				Authorization: `Bearer ${platformBearerToken}`,
				DiaToken: userToken,
			},
		})
		.then((res) => {
			return res
		})
		.catch((err) => {
			throw new Error(err.response.data.error_txt)
		})
}
