import React from 'react'
import Button from 'react-bootstrap/esm/Button'
import { useDrag, useDrop } from 'react-dnd'
import { BsArrowsMove } from 'react-icons/bs'
import {
	RiText,
	RiFileTextLine,
	RiFileTextFill,
	RiHtml5Line,
	RiYoutubeLine,
	RiRadioButtonLine,
	RiQuestionnaireLine,
	RiVimeoLine,
} from 'react-icons/ri'
import parse from 'html-react-parser'

import ReactPlayer from 'react-player/lazy'

import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Table from 'react-bootstrap/esm/Table'
const TYPE_ACCEPT = 'tr'

export const BlockRow = ({ index, block, moveRow, blockEdit, handleBlockDelete }) => {
	const dropRef = React.useRef(null)
	const dragRef = React.useRef(null)

	const [, drop] = useDrop({
		accept: TYPE_ACCEPT,
		hover(item, monitor) {
			if (!dropRef.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = dropRef.current.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveRow(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag, preview] = useDrag({
		item: { index },
		type: TYPE_ACCEPT,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const opacity = isDragging ? 0 : 1

	preview(drop(dropRef))
	drag(dragRef)

	const findNaturalIcon = (block) => {
		if (block.type === 'text') {
			return <RiFileTextLine style={{ fontSize: '40px' }} />
		}
		if (block.type === 'richtext') {
			return <RiFileTextFill style={{ fontSize: '40px' }} />
		}
		if (block.type === 'html') {
			return <RiHtml5Line style={{ fontSize: '40px' }} />
		}
		if (block.type === 'youtube') {
			return <RiYoutubeLine style={{ fontSize: '40px' }} />
		}
		if (block.type === 'button') {
			return <RiRadioButtonLine style={{ fontSize: '40px' }} />
		}
		if (block.type === 'openquestion') {
			return <RiQuestionnaireLine style={{ fontSize: '40px' }} />
		}
	}
	const findNaturalContent = (block) => {
		if (block.type === 'text') {
			return (
				<Row>
					<Col md={12} className="text-left ">
						<div className="card p-3" style={{ position: 'relative' }}>
							<span className="badge blockBadge">
								Contenido: Texto simple <RiFileTextLine />
							</span>
							<p style={{ whiteSpace: 'pre-line' }}>{block.content.text}</p>
						</div>
					</Col>
				</Row>
			)
		}
		if (block.type === 'richtext') {
			return (
				<Row>
					<Col md={12} className="text-left ">
						<div className="card p-3">
							<span className="badge blockBadge">
								Contenido: Texto con formato <RiFileTextFill />
							</span>
							<p>{parse(block.content.text)}</p>
						</div>
					</Col>
				</Row>
			)
		}
		if (block.type === 'html') {
			return (
				<Row>
					<Col md={12} className="text-left ">
						<div className="card p-3">
							<span className="badge blockBadge">
								Contenido: HTML libre <RiHtml5Line />
							</span>
							<div dangerouslySetInnerHTML={{ __html: block.content.html }}></div>
						</div>
					</Col>
				</Row>
			)
		}
		if (block.type === 'youtube') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3 pt-5" style={{ minHeight: '500px' }}>
							<span className="badge blockBadge">
								Interacción: Video Youtube <RiYoutubeLine />
							</span>
							<Row>
								<Col md={{ span: 12, offset: 0 }} style={{ position: 'relative', minHeight: '500px' }}>
									<ReactPlayer className="react-player" light={true} width="100%" height="100%" url={block.content.id} />
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			)
		}

		if (block.type === 'vimeo') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3 pt-5" style={{ minHeight: '500px' }}>
							<span className="badge blockBadge">
								Interacción: Video Vimeo <RiVimeoLine />
							</span>
							<Row>
								<Col md={{ span: 12, offset: 0 }} style={{ position: 'relative', minHeight: '500px' }}>
									<ReactPlayer className="react-player" light={true} width="100%" height="100%" url={block.content.id} />
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			)
		}
		if (block.type === 'button') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3">
							<span className="badge blockBadge">
								Interacción: Botón con link <RiRadioButtonLine />
							</span>
							<a href={block.content.url}>
								<span className="btn btn-primary">{block.content.text}</span>
							</a>
						</div>
					</Col>
				</Row>
			)
		}

		if (block.type === 'openquestion') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3">
							<span className="badge blockBadge">
								Interacción: Pregunta abierta <RiQuestionnaireLine />
							</span>
							<Row className="pt-4">
								<Col md={6} className="mt-2">
									<div className="card p-3" style={{ minHeight: '100px' }}>
										<h4 className="text-hint">Pregunta abierta</h4>
										<h5>{block.content.text}</h5>
									</div>
								</Col>
								<Col md={6} className="mt-2">
									<div className="card p-3" style={{ minHeight: '100px' }}>
										<h4 className="text-hint">Area de respuestas</h4>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			)
		}
		if (block.type === 'additionalresources') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3">
							<span className="badge blockBadge">
								Recursos adicionales <RiRadioButtonLine />
							</span>
							{block.content.length} Recursos
							{/* table */}
							<Table striped bordered responsive className="module-table mt-3">
								<thead>
									<tr>
										<th className="col-md-2">Tipo</th>
										<th className="col-md-10">Nombre</th>
									</tr>
								</thead>
								<tbody>
									{block.content.resources &&
										block.content.resources.length > 0 &&
										block.content.resources.map((file, index) => (
											<tr key={index}>
												<td>{file.type}</td>
												<td>{file.name}</td>
											</tr>
										))}
								</tbody>
							</Table>
							{/* table */}
						</div>
					</Col>
				</Row>
			)
		}

		if (block.type === 'thread') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3">
							<span className="badge blockBadge">
								Interacción: Tema de Foro <RiQuestionnaireLine />
							</span>
							<Row className="pt-4">
								<Col md={12} className="mt-2">
									<div className="card p-3" style={{ minHeight: '100px' }}>
										<h4 className="text-hint">{block.content.title}</h4>
										<h5>{block.content.text}</h5>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			)
		}

		if (block.type === 'cloud') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3">
							<span className="badge blockBadge">
								Interacción: Nube de Palabras <RiQuestionnaireLine />
							</span>
							<Row className="pt-4">
								<Col md={12} className="mt-2">
									<div className="card p-3" style={{ minHeight: '100px' }}>
										<h4 className="text-hint">{block.content.title}</h4>
										<h5>Número de respuestas: {block.content.answers}</h5>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			)
		}

		if (block.type === 'quiz') {
			return (
				<Row>
					<Col md={12} className="text-center">
						<div className="card p-3">
							<span className="badge blockBadge">
								Interacción: Cuestionario <RiRadioButtonLine />
							</span>
							<Row className="pt-4">
								<Col md={12} className="mt-2">
									<div className="card p-3" style={{ minHeight: '100px' }}>
										{/* <h4 className="text-hint">{block.content.title}</h4> */}
										<h5>Quiz ID: {block.content.id}</h5>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			)
		}
	}

	return (
		<tr ref={dropRef} style={{ opacity }}>
			<td ref={dragRef} className="text-center pt-4">
				<BsArrowsMove style={{ fontSize: '30px' }} />
			</td>
			{/* <td className="text-center pt-4">{findNaturalIcon(block)}</td> */}
			<td className="slide-preview">{findNaturalContent(block)}</td>
			{/* <td className="ellipsis">{module.description}</td> */}
			<td className="module-list-actions pt-3">
				<Button
					className="module-btn module-btn-edit"
					variant="success"
					size="sm"
					onClick={() => {
						blockEdit(block.id)
					}}
				>
					Editar
				</Button>
				<Button
					className="module-btn module-btn-delete"
					variant="danger"
					size="sm"
					onClick={() => {
						handleBlockDelete(block)
					}}
				>
					Eliminar
				</Button>
			</td>
		</tr>
	)
}
