import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeHTML({ blockType, blockContent, setBlockContent, lastError }) {
	return (
		<>
			{blockType === 'html' && (
				<Form.Group className="mb-3">
					<Form.Label>Código libre HTML</Form.Label>
					<Form.Control
						as="textarea"
						rows={10}
						placeholder="Pega aquí el código HTML"
						value={blockContent.html}
						onChange={(e) => setBlockContent({ html: e.target.value })}
						className={lastError === 'blockContent' && 'with-error'}
					/>
				</Form.Group>
			)}
		</>
	)
}
