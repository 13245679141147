import React, { useContext } from 'react'
import Container from 'react-bootstrap/esm/Container'

// Custom components
import SeHeader from './shared/SeHeader'
import SeHero from './shared/SeHero'
import SeFooter from './shared/SeFooter'
import { Access } from '../pages/shared/Access'
import BasePage from '../pages/shared/BasePage'
import { Route } from 'react-router'
import { UserContext } from '../context/shared/UserContext'
import UserLoadingRow from '../components/shared/UserLoadingRow'
import CourseList from '../pages/CourseTable'
import CourseAdd from '../pages/CourseAdd'
import ContentManagement from '../pages/ContentManagement'
import CourseTable from '../pages/CourseTable'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

export function Default({ setLoadingBar, setAlert }) {
	const { user, userBusy } = useContext(UserContext)
	return (
		<>
			<SeHeader />
			<Container className="se-container" fluid>
				<SeHero titleText="Administración de Cursos en línea" />
				<Route
					path={['/cursos']}
					exact
					render={(props) => (
						<BasePage requeriments={['user']}>
							<CourseTable setAlert={setAlert} setLoadingBar={setLoadingBar} />
						</BasePage>
					)}
				/>
				<Route
					path={['/cursos/agregar', '/cursos/:id/editar']}
					exact
					render={(props) => (
						<BasePage requeriments={['user']}>
							<CourseAdd setLoadingBar={setLoadingBar} />
						</BasePage>
					)}
				/>
				<Route
					path={['/cursos/:courseId/modulos']}
					render={(props) => (
						<BasePage requeriments={['user']}>
							<ContentManagement setAlert={setAlert} setLoadingBar={setLoadingBar} />
						</BasePage>
					)}
				/>

				<Route path={['/', '/recuperar-cuenta', '/recuperar-cuenta/:recoverCode']} exact>
					<Access setLoadingBar={setLoadingBar} setAlert={setAlert} />
				</Route>

				<Route path={['/']} exact>
					{user && (
						<Row>
							<Col md={12} className="text-center pt-5">
								<p>En un momento serás redireccionado...</p>
							</Col>
						</Row>
					)}
				</Route>

				{/* {user && <p>Logeado</p>} */}
				<div className="push"></div>
			</Container>
			<SeFooter />
		</>
	)
}
