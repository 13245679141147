import '../css/_CourseList.css'
import React, { useContext, useState, useEffect } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Alert from 'react-bootstrap/esm/Alert'
import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import { CourseContext } from '../context/CourseContext'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useToasts } from 'react-toast-notifications'

// vendor
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { useParams, useHistory } from 'react-router-dom'
import ButtonBackToCoursesIndex from '../components/ButtonBackToCoursesIndex'
import PageTitle from '../components/PageTitle'
import { UserContext } from '../context/shared/UserContext'
import axios from 'axios'
import { lmsPlataformURL, platformBearerToken } from '../classes/Environment'

export default function CourseAdd({ setLoadingBar, setAlert }) {
	const { id } = useParams() || false
	const history = useHistory()
	const { addToast } = useToasts()
	const { userToken } = useContext(UserContext)
	const { courses, courseAdd, courseUpdate, courseGetIndex } = useContext(CourseContext)

	const [lastError, setLastError] = useState(false)
	const [course, setCourse] = useState(false)
	const [title, setTitle] = useState('')
	const [slug, setSlug] = useState('')
	const [subtitle, setSubtitle] = useState('')
	const [description, setDescription] = useState('')
	const [hex, setHex] = useState('')
	const [date, setDate] = useState(false)
	const [published, setPublished] = useState(false)
	const [image, setImage] = useState(false)
	const [theme, setTheme] = useState('especialidad')
	const [hasUploader, setHasUploader] = useState(true)
	const [hasSimpleUploader, setHasSimpleUploader] = useState(false)
	const [hasForums, setHasForums] = useState(true)
	const [hasHelpdesk, setHasHelpdesk] = useState(true)

	// datepicker
	// datepicker
	const DatePickerExample = () => {
		return <DatePicker className="course-date-picker" selected={date} onChange={(date) => setDate(date)} />
	}
	// datepicker
	// datepicker

	// image
	// image
	// image
	const [picture, setPicture] = useState(null)
	const [pictureURL, setPictureURL] = useState(null)
	const [imgData, setImgData] = useState(null)
	const [imgDataStatus, setImgDataStatus] = useState(null)
	const [imgDataStatusVariant, setImgDataStatusVariant] = useState('info')
	const onChangePicture = (e) => {
		if (e.target.files[0]) {
			setImgDataStatus('Leyendo foto...')
			setPicture(e.target.files[0])
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setImgData(reader.result)
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}
	// image
	const imagePreview = () => {
		if (imgData) {
			return <div className="course-image" style={{ backgroundImage: 'url(' + imgData + ')' }}></div>
		} else {
			if (pictureURL) {
				return <div className="course-image" style={{ backgroundImage: 'url(' + pictureURL + ')' }}></div>
			} else {
				return (
					<div className="course-image">
						<p>Curso Sin imagen</p>
					</div>
				)
			}
		}
	}
	// image
	useEffect(() => {
		if (!picture) {
			return
		}
		setLoadingBar(true)
		setImgDataStatusVariant('warning')
		setImgDataStatus('Subiendo foto...')

		let formData = new FormData()
		formData.append('file', picture)
		formData.append('instruction', 'cover')

		axios
			.post(`${lmsPlataformURL}upload`, formData, {
				headers: { Authorization: `Bearer ${platformBearerToken}`, DiaToken: userToken },
			})
			.then((res) => {
				setLoadingBar(false)
				setImgData(null)
				// console.log(res.data.url)
				setPictureURL(res.data.url)
				setImgDataStatusVariant('success')
				setImgDataStatus('La imagen se ha adjuntado correctamente')
			})
			.catch((err) => {
				setLoadingBar(false)
				setImgDataStatusVariant('danger')
				setImgDataStatus('Ocurrió un error al adjuntar la imagen. Por favor intenta con otro archivo')
				return
			})
	}, [picture])
	// image

	const validateForm = () => {
		if (imgDataStatus === 'Subiendo foto...') {
			addToast('Por favor espera a que se adjunte la imagen', {
				appearance: 'warning',
			})
			return
		}
		if (title === '') {
			addToast('Por favor especifica el título del curso', {
				appearance: 'warning',
			})
			setLastError('title')
			return
		}
		if (slug === '') {
			addToast('Por favor especifica el slug del curso', {
				appearance: 'warning',
			})
			setLastError('slug')
			return
		}
		// if (description === '') {
		// 	addToast('Por favor especifica la descripción del curso', {
		// 		appearance: 'warning',
		// 	})
		// 	setLastError('description')
		// 	return
		// }
		// if (!pictureURL) {
		// 	addToast('Por favor adjunta la imagen del curso', {
		// 		appearance: 'warning',
		// 	})
		// 	setLastError('pictureURL')
		// 	return
		// }
		return true
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateForm()) {
			return
		}

		let courseToAdd = {
			id: id ? id : false,
			title,
			slug,
			subtitle,
			description,
			hex,
			date,
			published,
			image: pictureURL,
			theme,
			hasSimpleUploader,
			hasUploader,
			hasForums,
			hasHelpdesk,
		}
		setLoadingBar(true)

		if (!id) {
			courseAdd(userToken, courseToAdd)
				.then((course) => {
					setLoadingBar(false)
					history.push('/cursos')
					addToast('El curso ha sido agregado correctamente', {
						appearance: 'success',
					})
				})
				.catch((err) => {
					setLoadingBar(false)
					addToast('Ocurrió un error al agregar el curso: ' + err, {
						appearance: 'error',
					})
				})
		} else {
			courseUpdate(userToken, courseToAdd)
				.then((course) => {
					setLoadingBar(false)
					history.push('/cursos')
					addToast('El curso ha sido actualizado correctamente', {
						appearance: 'success',
					})
				})
				.catch((err) => {
					setLoadingBar(false)
					addToast('Ocurrió un error al actualizar el curso: ' + err, {
						appearance: 'error',
					})
				})
		}
	}

	useEffect(() => {
		if (id) {
			if (courses.length === 0) {
				// Reload
				setLoadingBar(true)
				courseGetIndex(userToken)
				setLoadingBar(false)
				// Reload
			}
			// eslint-disable-next-line eqeqeq
			const currentCourse = courses.find((course) => course.id == id)
			if (currentCourse) {
				setTitle(currentCourse.title)
				setSlug(currentCourse.slug)
				setDescription(currentCourse.description)
				setSubtitle(currentCourse.subtitle)
				setHex(currentCourse.hex)
				if (currentCourse.date) {
					setDate(new Date(currentCourse.date))
				} else {
					setDate(false)
				}
				setImage(false)
				if (currentCourse.image) {
					setPictureURL(currentCourse.image)
				}
				setPublished(currentCourse.published)
				setTheme(currentCourse.theme)

				setHasUploader(currentCourse.hasUploader)
				setHasForums(currentCourse.hasForums)
				setHasHelpdesk(currentCourse.hasHelpdesk)
				setHasSimpleUploader(currentCourse.hasSimpleUploader)
			}
		}
	}, [courses])

	return (
		<Card className="courseList-card">
			<PageTitle title={id ? 'Editar un curso' : 'Agregar un curso'} />
			<Row className="justify-content-center">
				<Col md={8}>
					<Form id="course-form" onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Título</Form.Label>
							<Form.Control
								type="text"
								placeholder="Título del curso a crear"
								onChange={(e) => {
									setTitle(e.target.value)
								}}
								value={title}
								className={lastError === 'title' && 'with-error'}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
							<Form.Label>Slug</Form.Label>
							<Form.Control
								type="text"
								placeholder="Ejemplo: lectoescritura-verano"
								onChange={(e) => {
									setSlug(e.target.value)
								}}
								value={slug}
								className={lastError === 'slug' && 'with-error'}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
							<Form.Label>Subtítulo</Form.Label>
							<Form.Control
								type="text"
								placeholder=""
								onChange={(e) => {
									setSubtitle(e.target.value)
								}}
								value={subtitle}
								className={lastError === 'subtitle' && 'with-error'}
							/>
						</Form.Group>

						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label>Descripción general</Form.Label>
							<CKEditor
								editor={Editor}
								data={description}
								onReady={(editor) => {
									// You can store the "editor" and use when it is needed.
									// console.log('Editor is ready to use!', editor)
									editor.setData(description)
								}}
								onChange={(event, editor) => {
									setDescription(editor.getData())
								}}
								onBlur={(event, editor) => {
									// console.log('Blur.', editor)
								}}
								onFocus={(event, editor) => {
									// console.log('Focus.', editor)
								}}
							/>
						</Form.Group>

						<Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
							<Form.Label>Color hexadecimal</Form.Label>
							<Form.Control
								type="text"
								placeholder="Ejemplo: #D4D4D4"
								onChange={(e) => {
									setHex(e.target.value)
								}}
								value={hex}
								className={lastError === 'hex' && 'with-error'}
							/>
						</Form.Group>

						{/* Imagen */}
						<Row className="pt-3 pb-3 mb-3" style={{ border: '1px solid #e8e8e8' }}>
							<Col md={12} className="mb-4">
								<Form.Label>Imagen asociada</Form.Label>
							</Col>
							<Col className="text-center" md={6}>
								{imagePreview()}
							</Col>
							<Col className="" md={6}>
								<Form.Group controlId="formFile" className="mb-3">
									<Form.Label>Selecciona aquí tu archivo a subir</Form.Label>
									<br />
									<Form.Control type="file" onChange={onChangePicture} />
								</Form.Group>
								{imgDataStatus && (
									<Alert variant={imgDataStatusVariant} className="text-center">
										{imgDataStatus}
									</Alert>
								)}
							</Col>
						</Row>
						{/* Imagen */}
						<hr />
						<h5 className="text-center">Opciones avanzadas</h5>
						<Form.Group className="mb-3">
							<Form.Label>Estilo</Form.Label>
							<Form.Control
								as="select"
								placeholder="Selecciona el estilo del curso"
								value={theme}
								onChange={(e) => setTheme(e.target.value)}
								className={lastError === 'theme' && 'with-error'}
							>
								<option key={0} value="cursos">
									Cursos Instituto DIA
								</option>
								<option key={1} value="especialidad">
									Especialidad
								</option>
							</Form.Control>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Check
								type="checkbox"
								label="Uploader"
								onChange={() => {
									setHasUploader(!hasUploader)
								}}
								checked={hasUploader}
								className={lastError === 'hasUploader' && 'with-error'}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Check
								type="checkbox"
								label="Uploader simplificado"
								onChange={() => {
									setHasSimpleUploader(!hasSimpleUploader)
								}}
								checked={hasSimpleUploader}
								className={lastError === 'hasSimpleUploader' && 'with-error'}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Check
								type="checkbox"
								label="Mesa de Ayuda"
								onChange={() => {
									setHasHelpdesk(!hasHelpdesk)
								}}
								checked={hasHelpdesk}
								className={lastError === 'hasHelpdesk' && 'with-error'}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Check
								type="checkbox"
								label="Foros de discusión"
								onChange={() => {
									setHasForums(!hasForums)
								}}
								checked={hasForums}
								className={lastError === 'hasForums' && 'with-error'}
							/>
						</Form.Group>

						<hr />
						<h5 className="text-center">Opciones de publicación</h5>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
							<Form.Label>Fecha de Publicación</Form.Label>
							<DatePickerExample />
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
							<Form.Check
								type="checkbox"
								label="Publicar curso"
								onChange={() => {
									setPublished(!published)
								}}
								checked={published}
								className={lastError === 'published' && 'with-error'}
							/>
						</Form.Group>
						{!published && (
							<Row>
								<Col md={12} className="pt-3 pb-4 text-center">
									<span className="alert alert-info">
										Los cursos no publicados aparecen en la parte inferior de tu listado de cursos en
										https://cursos.institutodia.mx
									</span>
								</Col>
							</Row>
						)}

						<Row>
							<Col md={12} className="text-center">
								<Button type="submit" className="course-btn-add" variant={id ? 'warning' : 'success'}>
									{id ? 'Editar curso' : 'Agregar curso'}
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<ButtonBackToCoursesIndex />
		</Card>
	)
}
