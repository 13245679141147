import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Card from 'react-bootstrap/esm/Card'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { RiListCheck } from 'react-icons/ri'
import BlockAdd from '../components/BlockAdd'
import BlockTable from '../components/BlockTable'
import { CourseContext } from '../context/CourseContext'
import Locator from '../components/Locator'
import { UserContext } from '../context/shared/UserContext'

export default function BlockManagement({ setLoadingBar, setAlert }) {
	const { courseId, moduleId, lessonId } = useParams()
	// console.log('router', lessonId)
	const { addToast } = useToasts()

	const [selectedBlock, setSelectedBlock] = useState(null)
	const [currentCourse, setCurrentCourse] = useState(false)
	const [currentModule, setCurrentModule] = useState(false)

	const [currentLesson, setCurrentLesson] = useState(false)
	const [blocks, setBlocks] = useState(false)

	const { userToken } = useContext(UserContext)
	const { courses, blockAdd, blockUpdate, blockDelete, moduleGetIndex, moduleUpdate } = useContext(CourseContext)

	const handleNewBlockOrder = () => {
		setLoadingBar(true)
		const newLesson = currentLesson
		newLesson.blocks = blocks
		const newModuleLessons = currentModule.lessons.map((eachLesson) => (eachLesson.id == currentLesson.id ? newLesson : eachLesson))
		const newModule = currentModule
		newModule.lessons = newModuleLessons
		moduleUpdate(userToken, courseId, newModule)
			.then((newModule) => {
				setLoadingBar(false)
				addToast('El orden ha sido actualizado correctamente', {
					appearance: 'success',
				})
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al guardar el nuevo orden: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const blockEdit = (blockId) => {
		setSelectedBlock(blockId)
		return
	}
	const handleBlockAdd = (block) => {
		setLoadingBar(true)
		blockAdd(userToken, courseId, moduleId, lessonId, block)
			.then((newModule) => {
				setLoadingBar(false)
				addToast('El bloque ha sido agregado correctamente', {
					appearance: 'success',
				})
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al agregar el bloque: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const handleBlockUpdate = (block) => {
		setLoadingBar(true)
		blockUpdate(userToken, courseId, moduleId, lessonId, block)
			.then((newModule) => {
				setLoadingBar(false)
				addToast('El bloque ha sido actualizado correctamente', {
					appearance: 'success',
				})
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al actualizar el bloque: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const handleBlockDelete = (block) => {
		const doDelete = () => {
			setLoadingBar(true)
			blockDelete(userToken, courseId, moduleId, lessonId, block)
				.then((newModule) => {
					setLoadingBar(false)
					addToast('El bloque ha sido eliminado', {
						appearance: 'success',
					})
				})
				.catch((err) => {
					setLoadingBar(false)
					addToast('Ocurrió un error al eliminar el bloque: ' + err, {
						appearance: 'error',
					})
				})
		}
		setAlert({
			type: 'warning',
			title: 'Confirmación',
			message: 'Estas seguro de querer eliminar este bloque?',
			confirmBtnText: 'Eliminar',
			confirmBtnBsStyle: 'danger',
			cancelBtnText: 'Cancelar',
			cancelBtnBsStyle: 'warning',
			showCancel: true,
			onCancel: () => {
				setAlert(false)
			},
			onConfirm: () => {
				doDelete()
				setAlert(false)
			},
		})

		return
	}

	useEffect(() => {
		if (lessonId) {
			if (courses.length === 0) {
				setLoadingBar(true)
				moduleGetIndex(userToken, courseId)
				setLoadingBar(false)
			} else {
				// Si viene desde Cursos, no tiene info de Módulos
				const currentCourse = courses.find((course) => course.id == courseId)
				if (!currentCourse.content) {
					setLoadingBar(true)
					moduleGetIndex(userToken, courseId)
					setLoadingBar(false)
				}
			}

			// eslint-disable-next-line eqeqeq
			const currentCourse = courses.find((course) => course.id == courseId)
			if (currentCourse) {
				const currentModule = currentCourse.content.modules.find((module) => module.id == moduleId)
				const currentLesson = currentModule.lessons.find((lesson) => lesson.id == lessonId)

				setCurrentCourse(currentCourse)
				setCurrentModule(currentModule)
				setCurrentLesson(currentLesson)
				setBlocks(currentLesson.blocks)
			}
		}
	}, [lessonId, courses])

	return (
		<>
			<Locator course={currentCourse} module={currentModule} lesson={currentLesson} />

			<BlockTable blocks={blocks} setBlocks={setBlocks} blockEdit={blockEdit} handleBlockDelete={handleBlockDelete} data={[1, 2, 3, 4, 5, 6]} />

			<Row>
				<Col md={12} className="text-center pb-3">
					<Button
						variant="primary"
						onClick={() => {
							setSelectedBlock(false)
						}}
					>
						Agregar Bloque
					</Button>
				</Col>
			</Row>
			<Col md={12} className="text-center pb-3">
				<Button
					variant="info"
					onClick={() => {
						handleNewBlockOrder()
					}}
				>
					Guardar orden
				</Button>
			</Col>
			{selectedBlock !== null && (
				<Row className="justify-content-center">
					<Col md={8}>
						<Card className="module-container-form">
							<BlockAdd
								id={selectedBlock}
								blocks={blocks}
								setSelectedBlock={setSelectedBlock}
								handleBlockUpdate={handleBlockUpdate}
								handleBlockAdd={handleBlockAdd}
								setAlert={setAlert}
								setLoadingBar={setLoadingBar}
							/>
						</Card>
					</Col>
				</Row>
			)}
			<Locator course={currentCourse} module={currentModule} lesson={currentLesson} />
			<Row>
				<Col md={12} className="text-left">
					<hr />
					<Row>
						<Col md={4} className="text-center"></Col>
						<Col md={4} className="text-center">
							<Link to={`/cursos/${courseId}/modulos/${moduleId}/lecciones`}>
								<Button variant="warning">
									<RiListCheck /> Regresar al índice de lecciones
								</Button>
							</Link>
						</Col>
						<Col md={4} className="text-center"></Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}
