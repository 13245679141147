import React from 'react'

export default function PageTitle({
	title = 'Page Title',
	subTitle = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, exercitationem.',
}) {
	return (
		<div className="text-center mb-4">
			<h4 className="text-center">{title}</h4>
			{/* <small>{subTitle}</small> */}
		</div>
	)
}
