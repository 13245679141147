import React from 'react'
import { useState } from 'react'
import Alert from 'react-bootstrap/esm/Alert'
import Button from 'react-bootstrap/esm/Button'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Table from 'react-bootstrap/esm/Table'
import { useToasts } from 'react-toast-notifications'
import BlockTypeAdditionalResourcesUpload from './BlockTypeAdditionalResourcesUpload'

export default function BlockTypeAdditionalResources({
	blockType,
	blockContent,
	setBlockContent,
	lastError,
	handleSetBlockContent,
	setLoadingBar,
	setAlert,
}) {
	const { addToast } = useToasts()

	const [resourceLoop, setResourceLoop] = useState(false)

	const [resourceType, setResourceType] = useState('attachment')
	const [resourceName, setResourceName] = useState('')
	const [resourceURL, setResourceURL] = useState('')
	const [resourceFile, setResourceFile] = useState(false)
	const [resourceWorking, setResourceWorking] = useState(false)

	const handleFormAdd = () => {
		if (!resourceName || resourceName === '') {
			addToast('Por favor especifica el nombre del recurso', {
				appearance: 'error',
			})
			return
		}
		if (resourceType === 'attachment') {
			if (resourceURL === '') {
				addToast('Por favor adjunta el archivo del recurso', {
					appearance: 'error',
				})
				return
			}
		}
		if (resourceWorking) {
			addToast('Por favor espera a que el archivo termine de cargar', {
				appearance: 'warning',
			})
			return
		}
		if (resourceLoop !== false) {
			const resource = {
				type: resourceType,
				name: resourceName,
				url: resourceURL,
				file: resourceFile,
			}

			let prev = [...blockContent.resources]
			// eslint-disable-next-line eqeqeq
			let next = prev.map((x, index) => (index == resourceLoop ? resource : x))
			const newBlockContent = { resources: next }
			setBlockContent(newBlockContent)
		} else {
			const resource = {
				type: resourceType,
				name: resourceName,
				url: resourceURL,
				file: resourceFile,
			}
			if (!blockContent.resources) {
				blockContent.resources = []
			}
			const newBlockContent = { resources: [...blockContent.resources, resource] }
			setBlockContent(newBlockContent)
		}

		setResourceLoop(false)
		resetForm()
		addToast('El recurso ha sido agregado', {
			appearance: 'success',
		})
	}
	const resetForm = () => {
		setResourceType('url')
		setResourceName('')
		setResourceURL('')
		setResourceFile(false)
	}
	const handleEditResource = (index) => {
		console.log('handleEditResource', index)
		setResourceLoop(index)

		setResourceType(blockContent.resources[index].type)
		setResourceName(blockContent.resources[index].name)
		setResourceURL(blockContent.resources[index].url)
		setResourceFile(blockContent.resources[index].file)
	}
	const handleCancelEditResource = () => {
		setResourceLoop(false)
		resetForm()
	}

	const resourceDelete = (keyToDelete) => {
		let prev = [...blockContent.resources]
		let next = prev.filter((x, index) => index !== keyToDelete)
		console.log(next)
		const newBlockContent = { resources: next }
		setBlockContent(newBlockContent)
		addToast('El recurso ha sido eliminado', {
			appearance: 'success',
		})
	}
	const handleResourceDelete = (keyToDelete) => {
		setAlert({
			type: 'warning',
			title: 'Confirmación',
			message: 'Estas seguro de querer eliminar este recurso?',
			confirmBtnText: 'Eliminar',
			confirmBtnBsStyle: 'danger',
			cancelBtnText: 'Cancelar',
			cancelBtnBsStyle: 'warning',
			showCancel: true,
			onCancel: () => {
				setAlert(false)
			},
			onConfirm: () => {
				resourceDelete(keyToDelete)
				setAlert(false)
			},
		})
	}
	const hasResources = () => {
		if (typeof blockContent.resources === 'undefined') {
			return false
		}
		if (blockContent.resources) {
			if (blockContent.resources.length === 0) {
				return false
			}
		}
		return true
	}
	return (
		<>
			{blockType === 'additionalresources' && (
				<>
					<Row>
						<Col md={{ span: 10, offset: 1 }}>
							<h5 className="text-center">Recursos</h5>
							<Table striped bordered responsive className="module-table">
								<thead>
									<tr>
										<th className="col-md-6">Nombre</th>
										<th className="col-md-3">Tipo</th>
										<th className="col-md-3">Acciones</th>
									</tr>
								</thead>
								<tbody>
									{blockContent.resources &&
										blockContent.resources.length > 0 &&
										blockContent.resources.map((file, index) => (
											<tr key={index}>
												<td>{file.name}</td>
												<td>{file.type}</td>
												<td>
													{/* Acciones */}
													<Button
														className="btn"
														variant="primary"
														size="sm"
														onClick={() => {
															handleEditResource(index)
														}}
														style={{ marginRight: '5px' }}
													>
														Editar
													</Button>
													<Button
														className="btn"
														variant="danger"
														size="sm"
														onClick={() => {
															handleResourceDelete(index)
														}}
													>
														Eliminar
													</Button>
													{/* Acciones */}
												</td>
											</tr>
										))}
									{!hasResources() && (
										<tr>
											<td colSpan={3} className="text-center">
												<Alert variant="warning" className="mt-2">
													Por el momento no existen recursos registrados en este bloque
												</Alert>
											</td>
										</tr>
									)}
								</tbody>
							</Table>
							<h5 className="text-center">Agregar nuevo recurso</h5>
							<div className="card" style={{ padding: '15px', marginBottom: '20px' }}>
								<Form.Group className="mb-3">
									<Form.Label>Nombre del Recurso</Form.Label>
									<Form.Control
										type="text"
										onChange={(e) => {
											setResourceName(e.target.value)
										}}
										value={resourceName}
										className={lastError === 'resourceName' && 'with-error'}
									/>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>Tipo</Form.Label>
									<Form.Control
										as="select"
										placeholder="Selecciona el tipo de recurso"
										value={resourceType}
										onChange={(e) => setResourceType(e.target.value)}
										className={lastError === 'resourceType' && 'with-error'}
									>
										<option key={0} value="url">
											Dirección URL externa
										</option>
										<option key={1} value="attachment">
											Archivo adjunto
										</option>
									</Form.Control>
								</Form.Group>

								{resourceType === 'url' && (
									<Form.Group className="mb-3">
										<Form.Label>Dirección URL</Form.Label>
										<Form.Control
											type="text"
											onChange={(e) => {
												setResourceURL(e.target.value)
											}}
											value={resourceURL}
											className={lastError === 'resourceURL' && 'with-error'}
										/>
									</Form.Group>
								)}
								{resourceType === 'attachment' && (
									<>
										<Form.Group controlId="formFile" className="mb-3">
											<BlockTypeAdditionalResourcesUpload
												resourceWorking={resourceWorking}
												setResourceWorking={setResourceWorking}
												resourceURL={resourceURL}
												setResourceURL={setResourceURL}
												setLoadingBar={setLoadingBar}
											/>
											{/* <Form.Control type="file" onChange={{}} /> */}
										</Form.Group>
									</>
								)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="text-center">
							<Button
								className="btn btn-sm"
								variant={resourceLoop !== false ? 'warning' : 'primary'}
								onClick={handleFormAdd}
								style={{ marginRight: '5px' }}
							>
								{resourceLoop !== false ? 'Editar recurso' : 'Agregar recurso'}
							</Button>
							{resourceLoop !== false && (
								<Button className="btn btn-sm" variant="danger" onClick={handleCancelEditResource}>
									Cancelar edición
								</Button>
							)}

							<hr />
						</Col>
					</Row>
				</>
			)}
		</>
	)
}
