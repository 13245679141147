import React from 'react'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Table from 'react-bootstrap/esm/Table'
import Alert from 'react-bootstrap/esm/Alert'
import { LessonRow } from './LessonRow'
import { BlockRow } from './BlockRow'

export default function BlockTable({ blocks, setBlocks, blockEdit, handleBlockDelete }) {
	const moveRow = (dragIndex, hoverIndex) => {
		const dragRecord = blocks[dragIndex]
		setBlocks(
			update(blocks, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRecord],
				],
			})
		)
	}
	return (
		<DndProvider backend={HTML5Backend}>
			<Table striped bordered responsive className="module-table">
				<thead>
					<tr>
						<th className="col-md-1">Ordenar</th>
						{/* <th className="col-md-1">Tipo</th> */}
						<th className="col-md-9">Contenido</th>
						<th className="col-md-2">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{blocks &&
						blocks.length > 0 &&
						blocks.map((block, index) => (
							<BlockRow
								key={index}
								index={index}
								block={block}
								moveRow={moveRow}
								blockEdit={blockEdit}
								handleBlockDelete={handleBlockDelete}
							></BlockRow>
						))}
					{!blocks ||
						(blocks.length === 0 && (
							<tr>
								<td colSpan={6} className="text-center">
									<Alert variant="warning" className="mt-2">
										Por el momento no existen bloques registrados en esta lección
									</Alert>
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		</DndProvider>
	)
}
