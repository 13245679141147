import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeText({ blockType, blockContent, setBlockContent, lastError }) {
	return (
		<>
			{blockType === 'text' && (
				<Form.Group className="mb-3">
					<Form.Label>Texto simple</Form.Label>
					<Form.Control
						as="textarea"
						rows={10}
						placeholder="Escribe aquí tu texto simplificado"
						value={blockContent.text}
						onChange={(e) => setBlockContent({ text: e.target.value })}
						className={lastError === 'blockContent.text' && 'with-error'}
					/>
				</Form.Group>
			)}
		</>
	)
}
