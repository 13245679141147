import React from 'react'
import Form from 'react-bootstrap/esm/Form'

// vendor
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

/*  
 npm remove file:./ckeditor5
 npm install --save @ckeditor/ckeditor5-react @ckeditor/ckeditor5-build-classic\n
 npm remove @ckeditor/ckeditor5-react @ckeditor/ckeditor5-build-classic\n
 npm install --save @ckeditor/ckeditor5-react @ckeditor/ckeditor5-build-classic\n
 npm install --save @ckeditor/ckeditor5-basic-styles
 npm remove @ckeditor/ckeditor5-basic-styles
 npm remove @ckeditor/ckeditor5-react @ckeditor/ckeditor5-build-classic
 npm add file:./ckeditor5
 npm start
 npm i @ckeditor/ckeditor5-react 
*/

export default function BlockTypeRichText({ blockType, blockContent, setBlockContent }) {
	return (
		<>
			{blockType === 'richtext' && (
				<Form.Group className="mb-3">
					<Form.Label>Texto enriquecido</Form.Label>
					<CKEditor
						editor={Editor}
						data={blockContent.text}
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							// console.log('Editor is ready to use!', editor)
						}}
						onChange={(event, editor) => {
							setBlockContent({ text: editor.getData() })
						}}
						onBlur={(event, editor) => {
							console.log('Blur.', editor)
						}}
						onFocus={(event, editor) => {
							console.log('Focus.', editor)
						}}
					/>
				</Form.Group>
			)}
		</>
	)
}
