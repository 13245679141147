import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/esm/Form'
import { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { RiLoginBoxLine } from 'react-icons/ri'
import { useToasts } from 'react-toast-notifications'
import { appDomain, platformBearerToken, platformURL } from '../../classes/Environment'
import { useContext } from 'react'
import { UserContext } from '../../context/shared/UserContext'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

export default function SeLogin({ setLoadingBar, setAlert }) {
	const { user, userLogin } = useContext(UserContext)
	const history = useHistory()

	const defaultEmail = appDomain === '.local' ? 'ricardo@tequilastudios.com' : ''
	const defaultPassword = appDomain === '.local' ? 'test' : ''

	const [userEmail, setUserEmail] = useState(defaultEmail)
	const [userPassword, setUserPassword] = useState(defaultPassword)

	const [lastError, setLastError] = useState(false)
	const { addToast } = useToasts()

	// Context
	// Context
	const handleUserLogin = (userEmail, userPassword) => {
		setLoadingBar(true)
		axios
			.post(
				`${platformURL}user/login`,
				{ data: { userEmail, userPassword } },
				{
					headers: { Authorization: `Bearer ${platformBearerToken}`, Managed: true },
				}
			)
			.then((res) => {
				userLogin(res.data)
				setLoadingBar(false)
				history.push('/cursos')
			})
			.catch((err) => {
				setLoadingBar(false)
				console.error(err)
				const error = err.response?.data?.error_txt || 'Error desconocido al iniciar sesión'
				setAlert({
					type: 'error',
					title: 'Error al iniciar sesión',
					message: error,
				})
			})
	}
	// Context
	// Context

	// Validation
	// Validation
	const onValidation = () => {
		if (userEmail === '') {
			addToast('Por favor especifica tu correo electrónico', {
				appearance: 'warning',
			})
			setLastError('userEmail')
			return
		}
		if (userPassword === '') {
			addToast('Por favor especifica tu contraseña', {
				appearance: 'warning',
			})
			setLastError('userPassword')
			return
		}
		return true
	}

	const onSubmit = (e) => {
		e.preventDefault()
		if (onValidation()) {
			handleUserLogin(userEmail, userPassword)
		}

		return false
	}
	return (
		<Row>
			<Col md={{ span: 6, offset: 3 }} className="text-center pt-4">
				<Row>
					<Col md={12}>
						<h4>Iniciar sesión</h4>
						<small>Si ya tienes una cuenta en alguna de nuestras plataformas, inicia sesión aquí</small>
					</Col>
				</Row>

				<Row>
					<Col md={12} className="pt-3">
						<Form id="se-form-login" className="se-form at-form" onSubmit={onSubmit}>
							<Form.Group controlId="formBasicUserEmail">
								<Form.Control
									type="text"
									placeholder="Introduce aquí tu correo electrónico"
									value={userEmail}
									onChange={(e) => setUserEmail(e.target.value)}
									className={lastError === 'userEmail' && 'with-error'}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicUserPassword">
								<Form.Control
									type="password"
									placeholder="Introduce aquí tu contraseña"
									value={userPassword}
									onChange={(e) => setUserPassword(e.target.value)}
									className={lastError === 'userPassword' && 'with-error'}
								/>
							</Form.Group>
							<Button size="md" variant="success" type="submit" form="se-form-login">
								<RiLoginBoxLine
									style={{
										position: 'relative',
										bottom: '1px',
										marginRight: '5px',
									}}
								/>
								Iniciar
							</Button>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
