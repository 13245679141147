import React, { useContext, useEffect } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { Route, useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import SeForgot from '../../components/shared/SeForgot'
import SeLogin from '../../components/shared/SeLogin'
import SeRestore from '../../components/shared/SeRestore'
import SeSignup from '../../components/shared/SeSignup'
import { UserContext } from '../../context/shared/UserContext'

export function Access({ setLoadingBar, setAlert }) {
	const { recoverCode } = useParams() || false
	const { user, userBusy } = useContext(UserContext)
	const history = useHistory()
	return (
		<>
			<Route path={['/']} exact>
				{!userBusy && !user && (
					<>
						<SeLogin setLoadingBar={setLoadingBar} setAlert={setAlert}></SeLogin>
						{/* <hr />
						<SeSignup setLoadingBar={setLoadingBar} setAlert={setAlert}></SeSignup> */}
						<Row>
							<Col md={12} className="text-center pt-4">
								<Link to="/recuperar-cuenta">
									<p>Olvidaste tu contraseña? Recupera tu cuenta aquí</p>
								</Link>
							</Col>
						</Row>
					</>
				)}
				{user && !userBusy && history.push('/cursos')}
			</Route>
			<Route path={['/recuperar-cuenta']} exact>
				<SeForgot setLoadingBar={setLoadingBar} setAlert={setAlert}></SeForgot>
			</Route>
			<Route path={['/recuperar-cuenta/:recoverCode']} exact>
				{recoverCode && (
					<>
						<SeRestore setLoadingBar={setLoadingBar} setAlert={setAlert} recoverCode={recoverCode}></SeRestore>
					</>
				)}
			</Route>
		</>
	)
}
