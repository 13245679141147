import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeYoutube({ blockType, blockContent, setBlockContent, lastError }) {
	return (
		<>
			{blockType === 'youtube' && (
				<Form.Group className="mb-3">
					<Form.Label>URL del video dentro de Youtube</Form.Label>
					<Form.Control
						type="text"
						placeholder="Ejemplo: https://www.youtube.com/watch?v=Kp_kqamkYpw"
						onChange={(e) => {
							setBlockContent({ id: e.target.value })
						}}
						value={blockContent.id}
						className={lastError === 'blockContent' && 'with-error'}
					/>
				</Form.Group>
			)}
		</>
	)
}
