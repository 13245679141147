import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useToasts } from 'react-toast-notifications'

import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import BlockTypeRichText from './BlockTypeRichText'
import BlockTypeText from './BlockTypeText'
import BlockTypeYoutube from './BlockTypeYoutube'
import BlockTypeVimeo from './BlockTypeVimeo'
import BlockTypeHTML from './BlockTypeHTML'
import BlockTypeButton from './BlockTypeButton'
import BlockTypeOpenQuestion from './BlockTypeOpenQuestion'
import BlockTypeAdditionalResources from './BlockTypeAdditionalResources'
import BlockTypeThread from './BlockTypeThread'
import BlockTypeCloud from './BlockTypeCloud'
import BlockTypeQuiz from './BlockTypeQuiz'

export default function BlockAdd({ id = false, blocks, setSelectedBlock, handleBlockUpdate, handleBlockAdd, setLoadingBar, setAlert }) {
	const { addToast } = useToasts()

	const [lastError, setLastError] = useState('')
	const [blockType, setBlockType] = useState('richtext')
	const [blockContent, setBlockContent] = useState({})

	const handleSetBlockContent = (content) => {
		// console.log(blockContent)

		if (blockType == 'cloud') {
			if (!content.answers) {
				content.answers = 1
			}
		}

		const newBlockContent = {
			...blockContent,
			...content,
		}
		setBlockContent(newBlockContent)
	}

	// submit
	const validateForm = () => {
		if (blockType === '') {
			addToast('Por favor especifica el tipo de bloque', {
				appearance: 'warning',
			})
			setLastError('blockType')
			return
		}
		return true
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateForm()) {
			return
		}

		let blockToPayload = {
			id: id !== false ? id : false,
			type: blockType,
			content: blockContent,
		}
		id ? handleBlockUpdate(blockToPayload) : handleBlockAdd(blockToPayload)
		// addToast('El bloque ha sido registrado correctamente', {
		// 	appearance: 'success',
		// })
		setSelectedBlock(null)
	}

	useEffect(() => {
		console.log('effect', id)
		if (id !== false) {
			// eslint-disable-next-line eqeqeq
			const currentBlock = blocks.find((block) => block.id == id)
			setBlockType(currentBlock.type)
			setBlockContent(currentBlock.content)
		}
	}, [id])

	// submit
	return (
		<Form id="course-form" onSubmit={handleSubmit}>
			<Form.Group className="mb-3">
				<Form.Label>Tipo</Form.Label>
				<Form.Control
					as="select"
					placeholder="Selecciona el tipo de bloque"
					value={blockType}
					onChange={(e) => setBlockType(e.target.value)}
					className={lastError === 'blockType' && 'with-error'}
				>
					<option key={0} value="text">
						Texto simple
					</option>
					<option key={1} value="richtext">
						Texto enriquecido
					</option>
					<option key={2} value="youtube">
						Video de youtube
					</option>
					<option key={3} value="vimeo">
						Video de vimeo
					</option>
					<option key={4} value="html">
						HTML
					</option>
					<option key={5} value="button">
						Botón
					</option>
					<option key={6} value="openquestion">
						Pregunta abierta
					</option>
					<option key={7} value="additionalresources">
						Recursos adicionales
					</option>
					<option key={8} value="thread">
						Tema de Foro
					</option>
					<option key={9} value="cloud">
						Nube de Palabras
					</option>
					<option key={10} value="quiz">
						Cuestionario
					</option>
				</Form.Control>
			</Form.Group>

			<BlockTypeRichText blockType={blockType} blockContent={blockContent} setBlockContent={setBlockContent} />
			<BlockTypeText blockType={blockType} blockContent={blockContent} setBlockContent={setBlockContent} lastError={lastError} />
			<BlockTypeYoutube blockType={blockType} blockContent={blockContent} setBlockContent={setBlockContent} lastError={lastError} />
			<BlockTypeVimeo blockType={blockType} blockContent={blockContent} setBlockContent={setBlockContent} lastError={lastError} />
			<BlockTypeHTML blockType={blockType} blockContent={blockContent} setBlockContent={setBlockContent} lastError={lastError} />
			<BlockTypeButton
				blockType={blockType}
				blockContent={blockContent}
				setBlockContent={setBlockContent}
				lastError={lastError}
				handleSetBlockContent={handleSetBlockContent}
			/>
			<BlockTypeOpenQuestion
				blockType={blockType}
				blockContent={blockContent}
				setBlockContent={setBlockContent}
				lastError={lastError}
				handleSetBlockContent={handleSetBlockContent}
			/>
			<BlockTypeAdditionalResources
				blockType={blockType}
				blockContent={blockContent}
				setBlockContent={setBlockContent}
				lastError={lastError}
				handleSetBlockContent={handleSetBlockContent}
				setAlert={setAlert}
				setLoadingBar={setLoadingBar}
			/>
			<BlockTypeThread
				blockType={blockType}
				blockContent={blockContent}
				setBlockContent={setBlockContent}
				lastError={lastError}
				handleSetBlockContent={handleSetBlockContent}
			/>
			<BlockTypeCloud
				blockType={blockType}
				blockContent={blockContent}
				setBlockContent={setBlockContent}
				lastError={lastError}
				handleSetBlockContent={handleSetBlockContent}
			/>
			<BlockTypeQuiz blockType={blockType} blockContent={blockContent} setBlockContent={setBlockContent} lastError={lastError} />

			<Row>
				<Col md={12} className="">
					<Button type="submit" className="course-btn-add" variant={id ? 'warning' : 'success'}>
						{id ? 'Editar bloque' : 'Agregar bloque'}
					</Button>
					<Button
						className="module-btn-cancel"
						variant="warning"
						onClick={() => {
							setSelectedBlock(null)
						}}
					>
						Cancelar
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
