/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
	switch (action.type) {
		case 'COURSES_SET':
			return {
				...state,
				courses: action.payload,
			}
		case 'COURSE_ADD':
			return {
				...state,
				courses: [...state.courses, action.payload],
			}
		case 'COURSE_UPDATE':
			return {
				...state,
				courses: [...state.courses.filter((course) => course.id != action.payload.id), action.payload],
			}
		case 'COURSE_DELETE':
			return {
				...state,
				courses: state.courses.filter((course) => course.id !== action.payload.id),
			}
		default:
			return state
	}
}
