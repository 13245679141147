import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useToasts } from 'react-toast-notifications'

import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import { useHistory } from 'react-router'

export default function LessonAdd({ id = false, lessons, setSelectedLesson, handleLessonUpdate, handleLessonAdd }) {
	const history = useHistory()
	const { addToast } = useToasts()

	const [lastError, setLastError] = useState('')
	const [lessonTitle, setLessonTitle] = useState('')
	const [lessonType, setLessonType] = useState('Lección')
	const [lessonPublishDate, setLessonPublishDate] = useState('')
	const [lessonEstimatedTime, setLessonEstimatedTime] = useState('')
	const [lessonPublished, setLessonPublished] = useState(true)

	// datepicker
	// datepicker
	const DatePickerExample = () => {
		return (
			<DatePicker
				className="course-date-picker"
				selected={lessonPublishDate}
				onChange={(lessonPublishDate) => setLessonPublishDate(lessonPublishDate)}
			/>
		)
	}
	// datepicker
	// datepicker

	// submit
	const validateForm = () => {
		if (lessonTitle === '') {
			addToast('Por favor especifica el título de la lección', {
				appearance: 'warning',
			})
			setLastError('lessonTitle')
			return
		}
		if (lessonType === '') {
			addToast('Por favor especifica el tipo de lección', {
				appearance: 'warning',
			})
			setLastError('lessonType')
			return
		}
		return true
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateForm()) {
			return
		}

		let lessonToPayload = {
			id: id ? id : false,
			title: lessonTitle,
			type: lessonType,
			estimatedTime: lessonEstimatedTime,
			date: lessonPublishDate,
			published: lessonPublished,
		}
		if (!id) {
			lessonToPayload.blocks = []
		}
		id ? handleLessonUpdate(lessonToPayload) : handleLessonAdd(lessonToPayload)
		setSelectedLesson(false)
	}

	useEffect(() => {
		if (id) {
			// eslint-disable-next-line eqeqeq
			const currentLesson = lessons.find((lesson) => lesson.id == id)
			setLessonTitle(currentLesson.title)
			setLessonType(currentLesson.type)
			setLessonEstimatedTime(currentLesson.estimatedTime)
			setLessonPublishDate(currentLesson.date ? new Date(currentLesson.date) : false)
			setLessonPublished(currentLesson.published)
		}
	}, [id])

	// submit
	return (
		<Form id="course-form" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
				<Form.Label>Título</Form.Label>
				<Form.Control
					type="text"
					placeholder="Título de la lección"
					onChange={(e) => {
						setLessonTitle(e.target.value)
					}}
					value={lessonTitle}
					className={lastError === 'lessonTitle' && 'with-error'}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
				<Form.Label>Tipo</Form.Label>
				<Form.Control
					type="text"
					placeholder="Ejemplo: Lección"
					onChange={(e) => {
						setLessonType(e.target.value)
					}}
					value={lessonType}
					className={lastError === 'lessonType' && 'with-error'}
				/>
			</Form.Group>

			{/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
				<Form.Label>Tiempo estimado de lectura</Form.Label>
				<Form.Control
					type="text"
					placeholder="Ejemplo: 45 minutos"
					onChange={(e) => {
						setLessonEstimatedTime(e.target.value)
					}}
					value={lessonEstimatedTime}
					className={lastError === 'lessonEstimatedTime' && 'with-error'}
				/>
			</Form.Group> */}
			<Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
				<Form.Label>Fecha de Publicación</Form.Label>
				<DatePickerExample />
			</Form.Group>
			{/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
				<Form.Check
					type="checkbox"
					label="Publicar lección"
					onChange={() => {
						setLessonPublished(!lessonPublished)
					}}
					checked={lessonPublished}
					className={lastError === 'lessonPublished' && 'with-error'}
				/>
			</Form.Group> */}
			<Row>
				<Col md={12} className="">
					<Button type="submit" className="course-btn-add" variant={id ? 'warning' : 'success'}>
						{id ? 'Editar lección' : 'Agregar lección'}
					</Button>
					<Button
						className="module-btn-cancel"
						variant="warning"
						onClick={() => {
							setSelectedLesson(false)
						}}
					>
						Cancelar
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
