import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Card from 'react-bootstrap/esm/Card'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { RiListCheck } from 'react-icons/ri'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import LessonAdd from '../components/LessonAdd'
import LessonTable from '../components/LessonTable'
import Locator from '../components/Locator'
import { CourseContext } from '../context/CourseContext'
import { UserContext } from '../context/shared/UserContext'

export default function LessonManagement({ setLoadingBar, setAlert }) {
	const { addToast } = useToasts()
	const { courseId, moduleId } = useParams()

	const { userToken } = useContext(UserContext)
	const { courses, lessonAdd, lessonUpdate, lessonRemove, moduleUpdate, moduleGetIndex } = useContext(CourseContext)

	const [selectedLesson, setSelectedLesson] = useState(false)
	const [currentCourse, setCurrentCourse] = useState(false)
	const [currentModule, setCurrentModule] = useState(false)
	const [lessons, setLessons] = useState([])

	const lessonEdit = (lessonId) => {
		setSelectedLesson(lessonId)
		return
	}
	const handleNewOrder = () => {
		setLoadingBar(true)
		currentModule.lessons = lessons
		moduleUpdate(userToken, courseId, currentModule)
			.then((newModule) => {
				setLoadingBar(false)
				addToast('El orden ha sido actualizado correctamente', {
					appearance: 'success',
				})
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al guardar el nuevo orden: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const handleLessonAdd = (lesson) => {
		setLoadingBar(true)
		lessonAdd(userToken, courseId, moduleId, lesson)
			.then((newModule) => {
				setLoadingBar(false)
				addToast('La lección ha sido agregada correctamente', {
					appearance: 'success',
				})
				// setCurrentModule(newModule)
				// setLessons(newModule.lessons)
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al agregar la lección: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const handleLessonUpdate = (lesson) => {
		setLoadingBar(true)
		lessonUpdate(userToken, courseId, moduleId, lesson)
			.then((newModule) => {
				setLoadingBar(false)
				addToast('La lección ha sido actualizada correctamente', {
					appearance: 'success',
				})
				// setCurrentModule(newModule)
				// setLessons(newModule.lessons)
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al actualizar la lección: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const handleLessonRemove = (lesson) => {
		setAlert({
			type: 'warning',
			title: 'Confirmación',
			message: 'Estas seguro de querer eliminar esta lección? Se eliminarán todos los recursos',
			confirmBtnText: 'Eliminar',
			cancelBtnText: 'Cancelar',
			cancelBtnBsStyle: 'warning',
			confirmBtnBsStyle: 'danger',
			showCancel: true,
			onCancel: () => {
				setAlert(false)
			},
			onConfirm: () => {
				setLoadingBar(true)
				lessonRemove(userToken, courseId, moduleId, lesson)
					.then((newModule) => {
						setLoadingBar(false)
						addToast('La lección ha sido eliminada', {
							appearance: 'success',
						})
						setCurrentModule(newModule)
						setLessons(newModule.lessons)
					})
					.catch((err) => {
						setLoadingBar(false)
						addToast('Ocurrió un error al eliminar la lección: ' + err, {
							appearance: 'error',
						})
					})
				setAlert(false)
			},
		})

		return
	}

	useEffect(() => {
		if (moduleId) {
			if (courses.length === 0) {
				setLoadingBar(true)
				moduleGetIndex(userToken, courseId)
				setLoadingBar(false)
			} else {
				// Si viene desde Cursos, no tiene info de Módulos
				const currentCourse = courses.find((course) => course.id == courseId)
				if (!currentCourse.content) {
					setLoadingBar(true)
					moduleGetIndex(userToken, courseId)
					setLoadingBar(false)
				}
			}

			// eslint-disable-next-line eqeqeq
			const currentCourse = courses.find((course) => course.id == courseId)
			if (currentCourse) {
				console.log('currentCourse')
				const moduleToParse = currentCourse.content.modules.find((module) => module.id == moduleId)
				setCurrentCourse(currentCourse)
				if (moduleToParse) {
					console.log('currentModule')
					setCurrentModule(moduleToParse)
					setLessons(moduleToParse.lessons)
				}
			}
		}
	}, [moduleId, courses])

	return (
		<>
			<Locator course={currentCourse} module={currentModule} />
			<LessonTable
				lessons={lessons}
				setLessons={setLessons}
				lessonEdit={lessonEdit}
				handleLessonRemove={handleLessonRemove}
				data={[1, 2, 3, 4, 5, 6]}
			/>

			<Row>
				<Col md={12} className="text-center pb-3">
					<Button
						variant="warning"
						onClick={() => {
							setSelectedLesson(0)
						}}
					>
						Agregar Lección
					</Button>
				</Col>
				<Col md={12} className="text-center pb-3">
					<Button
						variant="info"
						onClick={() => {
							handleNewOrder()
						}}
					>
						Guardar orden
					</Button>
				</Col>
			</Row>
			{selectedLesson !== false && (
				<Row className="justify-content-center">
					<Col md={8}>
						<Card className="module-container-form">
							<LessonAdd
								id={selectedLesson}
								lessons={lessons}
								setSelectedLesson={setSelectedLesson}
								handleLessonUpdate={handleLessonUpdate}
								handleLessonAdd={handleLessonAdd}
							/>
						</Card>
					</Col>
				</Row>
			)}
			<Locator course={currentCourse} module={currentModule} />
			<Row>
				<Col md={12} className="text-left">
					<hr />
					<Row>
						<Col md={4} className="text-center"></Col>
						<Col md={4} className="text-center">
							<Link to={`/cursos/${courseId}/modulos`}>
								<Button variant="warning">
									<RiListCheck /> Regresar al índice de módulos
								</Button>
							</Link>
						</Col>
						<Col md={4} className="text-center"></Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}
