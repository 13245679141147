import axios from 'axios'
import React, { createContext, useEffect, useReducer } from 'react'
import { appDomain, platformBearerToken, platformURL } from '../../classes/Environment'
import UserReducer from './UserReducer'

// Initial state
const initialState = {
	user: false,
	userToken: false,
	userBusy: true,
}

// Create context
export const UserContext = createContext(initialState)

// Provider component
export const UserProvider = ({ children }) => {
	const [state, dispatch] = useReducer(UserReducer, initialState)

	// Actions
	const userLogin = (user) => {
		dispatch({
			type: 'USER_LOGIN',
			payload: user,
		})

		// set Storage
		localStorage.setItem('se-dia.token', JSON.stringify(user.userToken))

		// set Cookie
		const today = new Date()
		const expires = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 10)
		// setCookie('iDiaDomainSessionToken', userToken, { path: '/', domain: appDomain, expires: expires })
		document.cookie = 'iDiaDomainSessionToken=' + user.userToken + '; Path=/; Domain=' + appDomain + '; Expires=' + expires + ';'
	}

	const userLogout = () => {
		// local storage
		localStorage.removeItem('se-dia.token')
		// cookie
		document.cookie = 'iDiaDomainSessionToken=; Path=/; Domain=' + appDomain + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'

		dispatch({
			type: 'USER_LOGOUT',
			payload: false,
		})
	}

	const setUserBusy = (isBusy) => {
		dispatch({
			type: 'USER_BUSY',
			payload: isBusy,
		})
	}

	const handleRefreshUser = () => {
		const token = JSON.parse(localStorage.getItem('se-dia.token')) || false
		if (token) {
			dispatch({ type: 'USER_BUSY', payload: true })

			axios
				.get(`${platformURL}user`, {
					headers: {
						Authorization: `Bearer ${platformBearerToken}`,
						DiaToken: token,
						Managed: true,
					},
				})
				.then((res) => {
					userLogin(res.data)
					dispatch({ type: 'USER_BUSY', payload: false })
				})
				.catch((err) => {
					console.error(err)
					dispatch({ type: 'USER_BUSY', payload: false })
				})
		} else {
			dispatch({ type: 'USER_BUSY', payload: false })
		}

		return initialState
	}

	useEffect(() => {
		handleRefreshUser()
	}, [])

	return (
		<UserContext.Provider
			value={{
				user: state.user,
				userToken: state.userToken,
				userBusy: state.userBusy,
				userLogin,
				userLogout,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
