import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Card from 'react-bootstrap/esm/Card'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { useParams } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import Locator from '../components/Locator'
import ModuleAdd from '../components/ModuleAdd'
import ModuleTable from '../components/ModuleTable'
import { CourseContext } from '../context/CourseContext'
import { UserContext } from '../context/shared/UserContext'

export default function ModuleManagement({ modules, setModules, setLoadingBar, setAlert }) {
	const { addToast } = useToasts()
	const { courseId } = useParams()
	const [selectedModule, setSelectedModule] = useState(false)
	const { userToken } = useContext(UserContext)
	const { courses, moduleUpdate, moduleAdd, moduleRemove, moduleGetIndex } = useContext(CourseContext)

	const [currentCourse, setCurrentCourse] = useState(false)

	const moduleEdit = (moduleId) => {
		setSelectedModule(moduleId)
		return
	}
	const handleModuleAdd = (module) => {
		module.order = currentCourse.content?.modules?.length + 1 || 0

		setLoadingBar(true)
		moduleAdd(userToken, courseId, module)
			.then((module) => {
				setLoadingBar(false)
				addToast('El módulo ha sido agregado correctamente', {
					appearance: 'success',
				})
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al agregar el módulo: ' + err, {
					appearance: 'error',
				})
			})

		return
	}
	const handleModuleUpdate = (module) => {
		module.order = 0

		setLoadingBar(true)
		moduleUpdate(userToken, courseId, module)
			.then((course) => {
				setLoadingBar(false)
				addToast('El módulo ha sido actualizado correctamente', {
					appearance: 'success',
				})
			})
			.catch((err) => {
				setLoadingBar(false)
				addToast('Ocurrió un error al actualizar el módulo: ' + err, {
					appearance: 'error',
				})
			})
		return
	}
	const handleModuleDelete = (module) => {
		setAlert({
			type: 'warning',
			title: 'Confirmación',
			message: 'Estas seguro de querer eliminar este módulo? Se eliminarán todas las lecciones y recursos',
			confirmBtnText: 'Eliminar',
			cancelBtnText: 'Cancelar',
			cancelBtnBsStyle: 'warning',
			confirmBtnBsStyle: 'danger',
			showCancel: true,
			onCancel: () => {
				setAlert(false)
			},
			onConfirm: () => {
				setLoadingBar(true)
				moduleRemove(userToken, courseId, module)
					.then(() => {
						setLoadingBar(false)
						addToast('El módulo ha sido eliminado', {
							appearance: 'success',
						})
					})
					.catch((err) => {
						setLoadingBar(false)
						addToast('Ocurrió un error al eliminar el módulo: ' + err, {
							appearance: 'error',
						})
					})
				setAlert(false)
			},
		})

		return
	}

	useEffect(() => {
		if (courseId) {
			// Si es reload natural
			if (courses.length === 0) {
				setLoadingBar(true)
				moduleGetIndex(userToken, courseId)
				setLoadingBar(false)
			} else {
				// Si viene desde Cursos, no tiene info de Módulos
				const currentCourse = courses.find((course) => course.id == courseId)
				if (!currentCourse.content) {
					setLoadingBar(true)
					moduleGetIndex(userToken, courseId)
					setLoadingBar(false)
				}
			}
			// eslint-disable-next-line eqeqeq
			const currentCourse = courses.find((course) => course.id == courseId)
			if (currentCourse) {
				setCurrentCourse(currentCourse)
				if (currentCourse.content?.modules) {
					setModules(currentCourse.content.modules)
				}
			}
		}
	}, [courseId, courses, modules])

	return (
		<>
			<Locator course={currentCourse} />

			<ModuleTable
				modules={modules}
				setModules={setModules}
				moduleEdit={moduleEdit}
				handleModuleDelete={handleModuleDelete}
				data={[1, 2, 3, 4, 5, 6]}
			/>

			<Row>
				<Col md={12} className="text-center pb-3">
					<Button
						variant="warning"
						onClick={() => {
							setSelectedModule(0)
						}}
					>
						Agregar Módulo
					</Button>
				</Col>
			</Row>
			{selectedModule !== false && (
				<Row className="justify-content-center">
					<Col md={8}>
						<Card className="module-container-form">
							<ModuleAdd
								id={selectedModule}
								modules={modules}
								setSelectedModule={setSelectedModule}
								handleModuleUpdate={handleModuleUpdate}
								handleModuleAdd={handleModuleAdd}
								setLoadingBar={setLoadingBar}
								setAlert={setAlert}
							/>
						</Card>
					</Col>
				</Row>
			)}
			<Locator course={currentCourse} />
		</>
	)
}
