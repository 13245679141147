import React from 'react'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Table from 'react-bootstrap/esm/Table'
import Alert from 'react-bootstrap/esm/Alert'
import { LessonRow } from './LessonRow'

export default function LessonTable({ lessons, setLessons, lessonEdit, handleLessonRemove }) {
	const moveRow = (dragIndex, hoverIndex) => {
		const dragRecord = lessons[dragIndex]
		setLessons(
			update(lessons, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRecord],
				],
			})
		)
	}
	return (
		<DndProvider backend={HTML5Backend}>
			<Table striped bordered responsive className="module-table">
				<thead>
					<tr>
						<th className="col-md-1">Ordenar</th>
						<th className="col-md-1">Numeración</th>
						<th className="col-md-1">Tipo</th>
						<th className="col-md-5">Título de Lección</th>
						<th className="col-md-3">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{lessons &&
						lessons.length > 0 &&
						lessons.map((lesson, index) => (
							<LessonRow
								key={index}
								index={index}
								lesson={lesson}
								moveRow={moveRow}
								lessonEdit={lessonEdit}
								handleLessonRemove={handleLessonRemove}
							></LessonRow>
						))}
					{lessons && lessons.length === 0 && (
						<tr>
							<td colSpan={6} className="text-center">
								<Alert variant="warning" className="mt-2">
									Por el momento no existen lecciones registradas en este módulo
								</Alert>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</DndProvider>
	)
}
