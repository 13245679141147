import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useToasts } from 'react-toast-notifications'

import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'

export default function ModuleAdd({ id = false, modules, setSelectedModule, handleModuleUpdate, handleModuleAdd, setLoadingBar, setAlert }) {
	const { addToast } = useToasts()

	const [lastError, setLastError] = useState('')
	const [moduleTitle, setModuleTitle] = useState('')
	const [moduleType, setModuleType] = useState('Módulo')
	// const [moduleDescription, setModuleDescription] = useState('')
	const [modulePublishDate, setModulePublishDate] = useState('')
	const [moduleEstimatedTime, setModuleEstimatedTime] = useState('')
	const [modulePublished, setModulePublished] = useState(true)

	// datepicker
	// datepicker
	const DatePickerExample = () => {
		return (
			<DatePicker
				className="course-date-picker"
				selected={modulePublishDate}
				onChange={(modulePublishDate) => setModulePublishDate(modulePublishDate)}
			/>
		)
	}
	// datepicker
	// datepicker

	// submit
	const validateForm = () => {
		if (moduleTitle === '') {
			addToast('Por favor especifica el título del módulo', {
				appearance: 'warning',
			})
			setLastError('moduleTitle')
			return
		}
		if (moduleType === '') {
			addToast('Por favor especifica el tipo de módulo', {
				appearance: 'warning',
			})
			setLastError('moduleType')
			return
		}
		// if (moduleDescription === '') {
		// 	addToast('Por favor especifica la descripción del módulo', {
		// 		appearance: 'warning',
		// 	})
		// 	setLastError('moduleDescription')
		// 	return
		// }
		return true
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateForm()) {
			return
		}

		let moduleToPayload = {
			id: id ? id : false,
			title: moduleTitle,
			type: moduleType,
			// description: moduleDescription,
			estimatedTime: moduleEstimatedTime,
			date: modulePublishDate,
			published: modulePublished,
		}

		if (!id) {
			moduleToPayload.lessons = []
		} else {
			const currentModule = modules.find((module) => module.id == id)
			moduleToPayload.lessons = currentModule.lessons
		}

		id ? handleModuleUpdate(moduleToPayload) : handleModuleAdd(moduleToPayload)
		setSelectedModule(false)
	}

	useEffect(() => {
		if (id) {
			const currentModule = modules.find((module) => module.id == id)

			setModuleTitle(currentModule.title)
			setModuleType(currentModule.type)
			setModulePublishDate(currentModule.date ? new Date(currentModule.date) : false)
			setModuleEstimatedTime(currentModule.estimatedTime)
			setModulePublished(currentModule.published)
		}
	}, [id])

	// submit
	return (
		<Form id="course-form" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
				<Form.Label>Título</Form.Label>
				<Form.Control
					type="text"
					placeholder="Título del módulo"
					onChange={(e) => {
						setModuleTitle(e.target.value)
					}}
					value={moduleTitle}
					className={lastError === 'moduleTitle' && 'with-error'}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
				<Form.Label>Tipo</Form.Label>
				<Form.Control
					type="text"
					placeholder="Ejemplo: Módulo"
					onChange={(e) => {
						setModuleType(e.target.value)
					}}
					value={moduleType}
					className={lastError === 'moduleType' && 'with-error'}
				/>
			</Form.Group>
			{/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
				<Form.Label>Descripción</Form.Label>
				<Form.Control
					as="textarea"
					rows={6}
					onChange={(e) => {
						setModuleDescription(e.target.value)
					}}
					value={moduleDescription}
					className={lastError === 'moduleDescription' && 'with-error'}
				/>
			</Form.Group> */}

			{/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
				<Form.Label>Tiempo estimado de lectura</Form.Label>
				<Form.Control
					type="text"
					placeholder="Ejemplo: 45 minutos"
					onChange={(e) => {
						setModuleEstimatedTime(e.target.value)
					}}
					value={moduleEstimatedTime}
					className={lastError === 'moduleEstimatedTime' && 'with-error'}
				/>
			</Form.Group> */}
			<Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
				<Form.Label>Fecha de Publicación</Form.Label>
				<DatePickerExample />
			</Form.Group>
			{/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
				<Form.Check
					type="checkbox"
					label="Publicar módulo"
					onChange={() => {
						setModulePublished(!modulePublished)
					}}
					checked={modulePublished}
					className={lastError === 'modulePublished' && 'with-error'}
				/>
			</Form.Group> */}
			<Row>
				<Col md={12} className="">
					<Button type="submit" className="course-btn-add" variant={id ? 'warning' : 'success'}>
						{id ? 'Editar módulo' : 'Agregar módulo'}
					</Button>
					<Button
						className="module-btn-cancel"
						variant="warning"
						onClick={() => {
							setSelectedModule(false)
						}}
					>
						Cancelar
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
