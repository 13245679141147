import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeThread({ blockType, blockContent, setBlockContent, lastError, handleSetBlockContent }) {
	return (
		<>
			{blockType === 'thread' && (
				<>
					<Form.Group className="mb-3">
						<Form.Label>Título</Form.Label>
						<Form.Control
							type="text"
							onChange={(e) => {
								handleSetBlockContent({ title: e.target.value })
							}}
							value={blockContent.title}
							className={lastError === 'blockContent' && 'with-error'}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Contenido</Form.Label>
						<Form.Control
							as="textarea"
							rows={10}
							placeholder="Escribe aquí el contenido del tema para el foro"
							value={blockContent.text}
							onChange={(e) => handleSetBlockContent({ text: e.target.value })}
							className={lastError === 'blockContent.text' && 'with-error'}
						/>
					</Form.Group>
				</>
			)}
		</>
	)
}
