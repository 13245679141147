import axios from 'axios'
import React, { createContext, useEffect, useReducer } from 'react'
import { lmsPlataformURL, platformBearerToken } from '../classes/Environment'
import CourseReducer from './CourseReducer'
import { courseDelete, coursePost, coursePut, moduleDelete, modulePost, modulePut } from './service/CourseService'

const defaultTestCourses = [
	{
		id: '0000001',
		title: 'Curso inicial de Prueba',
		slug: 'inicial-1',
		description: 'Descripción de Prueba de Curso',
		image: false,
		hex: '#E8E8E8',
		date: new Date(),
		published: true,
		content: {
			modules: [
				{
					id: '1',
					title: 'Primer módulo de Prueba',
					type: 'Módulo',
					description: 'Descripción del primer módulo de Prueba',
					estimatedTime: '45 minutos',
					date: new Date(),
					published: true,
					lessons: [
						{
							id: 1,
							title: 'Lección de Prueba #1',
							type: 'Lección',
							estimatedTime: '4 minutos',
							date: new Date(),
							published: true,
							blocks: [
								{
									id: 1,
									title: 'Bloque de Texto Simple',
									type: 'text',
									content: {
										text: 'Texto simple!',
									},
								},
								{
									id: 2,
									title: 'Bloque de Texto Enriquecido',
									type: 'richtext',
									content: {
										text: '<h2>asdasdasdasdasd</h2><p>jknabshjkdbn kahjsbd&nbsp;</p><p>kljnas dkjbnas <strong>kdj</strong> naskdasd</p><p style="text-align: center;">centrado</p><p>izquierda nuevamente</p><ul><li>Lista 1</li><li>Lista 2</li><li>Lista 3</li></ul><p>Imagen:</p>',
									},
								},
								{
									id: 3,
									title: 'Bloque de Video Youtube',
									type: 'youtube',
									content: {
										id: 'https://www.youtube.com/watch?v=7STa-tiQajQ',
									},
								},
								// {
								// 	id: 4,
								// 	title: 'Bloque de Video Vimeo',
								// 	type: 'vimeo',
								// 	content:{
								// 		id: 'https://vimeo.com/108650530'
								// 	}
								// },
								{
									id: 5,
									title: 'Bloque de HTML',
									type: 'html',
									content: {
										html: '<strong>Strong!</strong>',
									},
								},
								{
									id: 6,
									title: 'Botón Externo',
									type: 'button',
									content: {
										text: 'Ir a Google',
										url: 'https://google.com',
									},
								},
								{
									id: 7,
									title: 'Pregunta abierta',
									type: 'openquestion',
									content: {
										text: 'Texto de la pregunta abierta',
									},
								},
							],
						},
						{
							id: 2,
							title: 'Lección de Prueba #2',
							type: 'Lección',
							estimatedTime: '8 minutos',
							date: new Date(),
							published: true,
							blocks: [],
						},
					],
				},
				{
					id: '2',
					title: 'Segundo módulo de Prueba',
					type: 'Módulo',
					description: 'Descripción del segundo módulo de Prueba',
					estimatedTime: '45 minutos',
					date: new Date(),
					published: true,
				},
				{
					id: '3',
					title: 'Tercer módulo de Prueba',
					type: 'Módulo',
					description: 'Descripción del Tercer módulo de Prueba',
					estimatedTime: '45 minutos',
					date: new Date(),
					published: true,
				},
			],
		},
	},
	{
		id: '0000002',
		title: 'Segundo Curso inicial de Prueba',
		slug: 'inicial-2',
		description: 'Descripción de Prueba de Curso',
		image: false,
		hex: '#E8E8E8',
		date: new Date(),
		published: true,
	},
	{
		id: '0000003',
		title: 'Tercer Curso inicial de Prueba',
		slug: 'inicial-3',
		description: 'Descripción de Prueba de Curso',
		image: false,
		hex: '#E8E8E8',
		date: new Date(),
		published: true,
	},
]

// Initial state
const initialState = {
	courses: [],
}

// Create context
export const CourseContext = createContext(initialState)

// Provider component
export const CourseProvider = ({ children }) => {
	const [state, dispatch] = useReducer(CourseReducer, initialState)

	const courseGetIndex = (userToken) => {
		axios
			.get(`${lmsPlataformURL}course`, {
				headers: {
					Authorization: `Bearer ${platformBearerToken}`,
					DiaToken: userToken,
				},
			})
			.then((res) => {
				setCourses(res.data.index)
			})
			.catch((err) => {})
	}

	const moduleGetIndex = (userToken, courseId) => {
		axios
			.get(`${lmsPlataformURL}course/${courseId}/module`, {
				headers: {
					Authorization: `Bearer ${platformBearerToken}`,
					DiaToken: userToken,
				},
			})
			.then((res) => {
				dispatch({
					type: 'COURSE_UPDATE',
					payload: res.data.course,
				})
			})
			.catch((err) => {})
	}

	// Actions
	const setCourses = (courses) => {
		dispatch({
			type: 'COURSES_SET',
			payload: courses,
		})
	}
	const courseAdd = (userToken, course) => {
		return new Promise((resolve, reject) => {
			coursePost(userToken, course)
				.then((res) => {
					course.id = false
					dispatch({
						type: 'COURSE_ADD',
						payload: course,
					})
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const courseUpdate = (userToken, course) => {
		return new Promise((resolve, reject) => {
			coursePut(userToken, course)
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: course,
					})
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const courseRemove = (userToken, course) => {
		return new Promise((resolve, reject) => {
			courseDelete(userToken, course)
				.then((res) => {
					dispatch({
						type: 'COURSE_DELETE',
						payload: course,
					})
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	// Modulos
	const moduleAdd = (userToken, courseId, module) => {
		module.id = false
		return new Promise((resolve, reject) => {
			modulePost(userToken, courseId, module)
				.then((res) => {
					// eslint-disable-next-line eqeqeq
					const course = state.courses.find((course) => course.id == courseId)
					if (course.content?.modules) {
						course.content.modules = [...course.content.modules, module]
					} else {
						course.content = { modules: [module] }
					}
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const moduleUpdate = (userToken, courseId, module) => {
		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, module)
				.then((res) => {
					// eslint-disable-next-line eqeqeq
					const course = state.courses.find((course) => course.id == courseId)

					// save lessons
					// const currentModule = course.content.modules.find((eachModule) => eachModule.id == module.id)
					// lesson.blocks = currentLesson.blocks
					// save lessons

					// eslint-disable-next-line eqeqeq
					course.content.modules = [...course.content.modules.map((eachModule) => (eachModule.id == module.id ? module : eachModule))]
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const moduleRemove = (userToken, courseId, module) => {
		return new Promise((resolve, reject) => {
			moduleDelete(userToken, courseId, module)
				.then((res) => {
					// eslint-disable-next-line eqeqeq
					const course = state.courses.find((course) => course.id == courseId)
					// eslint-disable-next-line eqeqeq
					course.content.modules = course.content.modules.filter((m) => m.id != module.id)
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	// Lecciones
	const lessonAdd = (userToken, courseId, moduleId, lesson) => {
		// eslint-disable-next-line eqeqeq
		const courseLoop = state.courses.findIndex((course) => course.id == courseId)
		const moduleLoop = state.courses[courseLoop].content.modules.findIndex((module) => module.id == moduleId)
		const course = state.courses[courseLoop]
		if (course.content.modules[moduleLoop].lessons) {
			course.content.modules[moduleLoop].lessons = [...course.content.modules[moduleLoop].lessons, lesson]
		} else {
			course.content.modules[moduleLoop].lessons = [lesson]
		}

		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, course.content.modules[moduleLoop])
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(res.data.course.content.modules.find((module) => module.id == moduleId))
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const lessonUpdate = (userToken, courseId, moduleId, lesson) => {
		// eslint-disable-next-line eqeqeq
		const courseLoop = state.courses.findIndex((course) => course.id == courseId)
		const moduleLoop = state.courses[courseLoop].content.modules.findIndex((module) => module.id == moduleId)
		const course = state.courses[courseLoop]

		// Save blocks
		const currentLesson = course.content.modules[moduleLoop].lessons.find((eachLesson) => eachLesson.id == lesson.id)
		lesson.blocks = currentLesson.blocks
		// Save blocks

		course.content.modules[moduleLoop].lessons = [
			...course.content.modules[moduleLoop].lessons.map((eachLesson) => (eachLesson.id == lesson.id ? lesson : eachLesson)),
		]

		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, course.content.modules[moduleLoop])
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(res.data.course.content.modules.find((module) => module.id == moduleId))
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const lessonRemove = (userToken, courseId, moduleId, lesson) => {
		const courseLoop = state.courses.findIndex((course) => course.id == courseId)
		const moduleLoop = state.courses[courseLoop].content.modules.findIndex((module) => module.id == moduleId)
		const course = state.courses[courseLoop]
		course.content.modules[moduleLoop].lessons = [...course.content.modules[moduleLoop].lessons.filter((l) => l.id != lesson.id)]

		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, course.content.modules[moduleLoop])
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(res.data.course.content.modules.find((module) => module.id == moduleId))
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	// Bloques
	const blockAdd = (userToken, courseId, moduleId, lessonId, block) => {
		block.id = false
		// eslint-disable-next-line eqeqeq
		const courseLoop = state.courses.findIndex((course) => course.id == courseId)
		const moduleLoop = state.courses[courseLoop].content.modules.findIndex((module) => module.id == moduleId)
		const lessonLoop = state.courses[courseLoop].content.modules[moduleLoop].lessons.findIndex((lesson) => lesson.id == lessonId)
		const course = state.courses[courseLoop]

		if (course.content.modules[moduleLoop].lessons[lessonLoop].blocks) {
			course.content.modules[moduleLoop].lessons[lessonLoop].blocks = [...course.content.modules[moduleLoop].lessons[lessonLoop].blocks, block]
		} else {
			course.content.modules[moduleLoop].lessons[lessonLoop].blocks = [block]
		}

		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, course.content.modules[moduleLoop])
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(res.data.course.content.modules.find((module) => module.id == moduleId))
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const blockUpdate = (userToken, courseId, moduleId, lessonId, block) => {
		// eslint-disable-next-line eqeqeq
		const courseLoop = state.courses.findIndex((course) => course.id == courseId)
		const moduleLoop = state.courses[courseLoop].content.modules.findIndex((module) => module.id == moduleId)
		const lessonLoop = state.courses[courseLoop].content.modules[moduleLoop].lessons.findIndex((lesson) => lesson.id == lessonId)
		const course = state.courses[courseLoop]

		course.content.modules[moduleLoop].lessons[lessonLoop].blocks = [
			...course.content.modules[moduleLoop].lessons[lessonLoop].blocks.map((eachBlock) => (eachBlock.id == block.id ? block : eachBlock)),
		]

		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, course.content.modules[moduleLoop])
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(res.data.course.content.modules.find((module) => module.id == moduleId))
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
	const blockDelete = (userToken, courseId, moduleId, lessonId, block) => {
		const courseLoop = state.courses.findIndex((course) => course.id == courseId)
		const moduleLoop = state.courses[courseLoop].content.modules.findIndex((module) => module.id == moduleId)
		const lessonLoop = state.courses[courseLoop].content.modules[moduleLoop].lessons.findIndex((lesson) => lesson.id == lessonId)
		const course = state.courses[courseLoop]

		course.content.modules[moduleLoop].lessons[lessonLoop].blocks = [
			...course.content.modules[moduleLoop].lessons[lessonLoop].blocks.filter((eachBlock) => eachBlock.id != block.id),
		]
		return new Promise((resolve, reject) => {
			modulePut(userToken, courseId, course.content.modules[moduleLoop])
				.then((res) => {
					dispatch({
						type: 'COURSE_UPDATE',
						payload: res.data.course,
					})
					resolve(res.data.course.content.modules.find((module) => module.id == moduleId))
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	useEffect(() => {
		// handleRefreshUser()
	}, [])

	return (
		<CourseContext.Provider
			value={{
				courses: state.courses,
				courseGetIndex,
				moduleGetIndex,
				courseRemove,
				courseAdd,
				courseUpdate,
				moduleUpdate,
				moduleAdd,
				moduleRemove,
				lessonAdd,
				lessonUpdate,
				lessonRemove,
				blockAdd,
				blockUpdate,
				blockDelete,
			}}
		>
			{children}
		</CourseContext.Provider>
	)
}
