import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeQuiz({ blockType, blockContent, setBlockContent, lastError }) {
	return (
		<>
			{blockType === 'quiz' && (
				<Form.Group className="mb-3">
					<Form.Label>Quiz ID</Form.Label>
					<Form.Control
						type="text"
						placeholder="Ejemplo: quiz-5aec804c06f7f"
						onChange={(e) => {
							setBlockContent({ id: e.target.value })
						}}
						value={blockContent.id}
						className={lastError === 'blockContent' && 'with-error'}
					/>
				</Form.Group>
			)}
		</>
	)
}
