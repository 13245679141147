import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import Form from 'react-bootstrap/esm/Form'
import Button from 'react-bootstrap/esm/Button'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { useParams } from 'react-router-dom'
import { UserContext } from '../context/shared/UserContext'
import UploadService from '../services/UploadService'

const BlockTypeAdditionalResourcesUpload = ({ resourceURL, setResourceURL, resourceWorking, setResourceWorking, setLoadingBar }) => {
	const { courseId } = useParams() || false
	const { userToken } = useContext(UserContext)
	const [selectedFiles, setSelectedFiles] = useState(undefined)
	const [currentFile, setCurrentFile] = useState(undefined)
	const [progress, setProgress] = useState(0)
	const [message, setMessage] = useState('')

	const getFileInformation = (url) => (
		<Row>
			<Col md={12} className="text-center">
				<a href={url} target="_blank" rel="noreferrer">
					<Button variant="success">Descargar recurso adicional</Button>
				</a>
				<p>
					<input readOnly className="mt-3" type="text" value={url} style={{ width: '100%' }}></input>
				</p>
			</Col>
		</Row>
	)

	useEffect(() => {
		if (resourceURL !== '') {
			setMessage(getFileInformation(resourceURL))
		}
	}, [resourceURL])

	const selectFile = (event) => {
		setSelectedFiles(event.target.files)
	}

	const upload = () => {
		let currentFile = selectedFiles[0]

		setProgress(0)
		setCurrentFile(currentFile)
		setLoadingBar(true)
		setResourceWorking(true)

		UploadService.upload(userToken, courseId, currentFile, (event) => {
			setProgress(Math.round((100 * event.loaded) / event.total))
		})
			.then((response) => {
				setLoadingBar(false)
				setResourceWorking(false)
				setResourceURL(response.data.url)
				setMessage(getFileInformation(response.data.url))
				setProgress(0)
				setCurrentFile(undefined)
			})
			.catch(() => {
				setLoadingBar(false)
				setResourceWorking(false)
				setProgress(0)
				setMessage('No se puede adjuntar el archivo')
				setCurrentFile(undefined)
			})

		setSelectedFiles(undefined)
	}

	return (
		<div>
			{currentFile && (
				<div className="progress">
					<div
						className="progress-bar progress-bar-info progress-bar-striped"
						role="progressbar"
						aria-valuenow={progress}
						aria-valuemin="0"
						aria-valuemax="100"
						style={{ width: progress + '%' }}
					>
						{progress}%
					</div>
				</div>
			)}
			<div className="alert alert-light" role="alert">
				{message}
			</div>

			<Form.Label>Selecciona aquí tu archivo a subir</Form.Label>
			<br />

			<label className="btn btn-default">
				<input type="file" onChange={selectFile} />
			</label>

			<button className="btn btn-success" disabled={!selectedFiles} onClick={upload}>
				Adjuntar
			</button>
		</div>
	)
}

export default BlockTypeAdditionalResourcesUpload
