import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeCloud({ blockType, blockContent, setBlockContent, lastError, handleSetBlockContent }) {
	return (
		<>
			{blockType === 'cloud' && (
				<>
					<Form.Group className="mb-3">
						<Form.Label>Pregunta</Form.Label>
						<Form.Control
							type="text"
							onChange={(e) => {
								handleSetBlockContent({ title: e.target.value })
							}}
							value={blockContent.title}
							className={lastError === 'blockContent' && 'with-error'}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Número de respuestas</Form.Label>
						<Form.Control
							as="select"
							placeholder="Selecciona el número de respuestas"
							value={blockContent.answers}
							onChange={(e) => handleSetBlockContent({ answers: e.target.value })}
							className={lastError === 'blockContent.answers' && 'with-error'}
						>
							<option key={1} value="1">
								1
							</option>
							<option key={2} value="2">
								2
							</option>
							<option key={3} value="3">
								3
							</option>
						</Form.Control>
					</Form.Group>
				</>
			)}
		</>
	)
}
