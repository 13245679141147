import React from 'react'
import { Link } from 'react-router-dom'
import { GrTree } from 'react-icons/gr'

export default function Locator({ course, module, lesson }) {
	return (
		<div className="text-left mb-4">
			{!course && (
				<>
					<h3 style={{ color: '#000', marginBottom: '3px' }}>Índice de cursos</h3>
				</>
			)}
			{course && (
				<h3 style={{ color: '#000', marginBottom: '3px' }}>
					<Link to={`/cursos/${course.id}/modulos`}>
						{`${course.title}`} <span className="badge badge-course">{course.slug}</span>
					</Link>
				</h3>
			)}
			{module && (
				<h5 style={{ color: '#666', paddingLeft: '20px' }}>
					<GrTree style={{ fontSize: '14px', marginRight: '5px', position: 'relative', bottom: '3px' }} />
					<Link to={`/cursos/${course.id}/modulos/${module.id}/lecciones`}>
						{`${module.title}`} <span className="badge badge-module">{course.content.modules.findIndex((m) => m.id == module.id)}</span>
					</Link>
				</h5>
			)}
			{lesson && (
				<h6 style={{ color: '#999', paddingLeft: '40px' }}>
					<GrTree style={{ fontSize: '14px', marginRight: '5px', position: 'relative', bottom: '3px' }} />
					{`${lesson.title}`}{' '}
					<span className="badge badge-lesson">
						{course.content.modules.findIndex((m) => m.id == module.id)}.{module.lessons.findIndex((l) => l.id == lesson.id)}
					</span>
				</h6>
			)}
		</div>
	)
}
