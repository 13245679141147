import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import { Link } from 'react-router-dom'

export default function ButtonBackToCoursesIndex() {
	return (
		<Row>
			<Col md={12} className="text-left">
				<hr />
				<Link to="/cursos">
					<Button variant="info" className="lms-btn-back">
						Regresar al índice de cursos
					</Button>
				</Link>
			</Col>
		</Row>
	)
}
