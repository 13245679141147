import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'

import logoFooterInstitutoDIA from '../../assets/img/logos/IMP.png'

export default function SeHero({ titleText = 'Servicios Escolares' }) {
	return (
		<section className="se-hero">
			<Container fluid>
				<Row>
					<Col md={12} className="text-center pt-4 pb-3">
						<Link to="/">
							<img alt="Logo Instituto DIA" src={logoFooterInstitutoDIA} className="se-img-hero" />
						</Link>
						<Link to="/">
							<h3 className="mb-3">{titleText}</h3>
						</Link>
					</Col>
					<div className="se-hero-rayitas"></div>
				</Row>
			</Container>
		</section>
	)
}
