import axios from 'axios'
import React, { createContext, useEffect, useReducer } from 'react'
import { useContext } from 'react'
import { platformBearerToken, platformURL } from '../../classes/Environment'
import { UserContext } from './UserContext'
import AnchorsReducer from './AnchorsReducer'

// Initial state
const initialState = {
	anchors: false,
	currentAnchor: false,
	currentPeriod: false,
	courses: false,
	events: false,
	rubrica: false,
	payments: false,
	certificates: false,
	busy: true,
}

// Create context
export const AnchorsContext = createContext(initialState)

// Provider component
export const AnchorsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AnchorsReducer, initialState)
	const { userToken } = useContext(UserContext)

	// Actions
	const setAnchors = (anchors) => {
		dispatch({
			type: 'SET_ANCHORS',
			payload: anchors,
		})
	}
	const setCurrentAnchor = (currentAnchor) => {
		dispatch({
			type: 'SET_CURRENT_ANCHOR',
			payload: currentAnchor,
		})
	}
	const setCurrentPeriod = (currentPeriod) => {
		dispatch({
			type: 'SET_CURRENT_PERIOD',
			payload: currentPeriod,
		})
	}
	const setCourses = (courses) => {
		dispatch({
			type: 'SET_COURSES',
			payload: courses,
		})
	}
	const setEvents = (events) => {
		dispatch({
			type: 'SET_EVENTS',
			payload: events,
		})
	}
	const setRubrica = (rubrica) => {
		dispatch({
			type: 'SET_RUBRICA',
			payload: rubrica,
		})
	}
	const setPayments = (payments) => {
		dispatch({
			type: 'SET_PAYMENTS',
			payload: payments,
		})
	}
	const setCertificates = (certificates) => {
		dispatch({
			type: 'SET_CERTIFICATES',
			payload: certificates,
		})
	}
	const reset = () => {
		dispatch({
			type: 'RESET',
			payload: false,
		})
	}

	const fetchAnchors = () => {
		if (!userToken) {
			return
		}
		dispatch({
			type: 'SET_BUSY',
			payload: true,
		})
		axios
			.get(`${platformURL}anchor`, {
				headers: { Authorization: `Bearer ${platformBearerToken}`, DiaToken: userToken },
			})
			.then((res) => {
				setAnchors(res.data.anchors)
				dispatch({
					type: 'SET_BUSY',
					payload: false,
				})
			})
			.catch((err) => {
				console.error(err)
				dispatch({
					type: 'SET_BUSY',
					payload: false,
				})
			})
	}

	useEffect(() => {
		fetchAnchors()
	}, [userToken])

	useEffect(() => {
		console.info('[EFFECT] anchors', state.anchors)
		if (state.anchors !== false) {
			setCurrentAnchor(0)
		}
	}, [state.anchors])

	useEffect(() => {
		console.info('[EFFECT] currentAnchor', state.currentAnchor)
		if (state.anchors[state.currentAnchor]) {
			if (state.anchors[state.currentAnchor].periods && state.anchors[state.currentAnchor].periods[0]) {
				setCurrentPeriod(0)
			}
		}
	}, [state.currentAnchor])

	useEffect(() => {
		console.info('[EFFECT] currentPeriod', state.currentPeriod)

		if (state.anchors[state.currentAnchor]?.periods[state.currentPeriod]) {
			if (state.anchors[state.currentAnchor].periods[state.currentPeriod].courses) {
				setCourses(state.anchors[state.currentAnchor].periods[state.currentPeriod].courses)
			} else {
				setCourses(false)
			}
			if (state.anchors[state.currentAnchor].calendar) {
				setEvents(state.anchors[state.currentAnchor].calendar.events)
			} else {
				setEvents(false)
			}
			if (state.anchors[state.currentAnchor].periods[state.currentPeriod].rubrica) {
				setRubrica(state.anchors[state.currentAnchor].periods[state.currentPeriod].rubrica)
			} else {
				setRubrica(false)
			}
			if (state.anchors[state.currentAnchor].periods[state.currentPeriod].payments) {
				setPayments(state.anchors[state.currentAnchor].periods[state.currentPeriod].payments)
			} else {
				setPayments(false)
			}
			if (state.anchors[state.currentAnchor].certificates) {
				setCertificates(state.anchors[state.currentAnchor].certificates)
			} else {
				setCertificates(false)
			}
		}
	}, [state.currentPeriod])

	return (
		<AnchorsContext.Provider
			value={{
				anchors: state.anchors,
				currentAnchor: state.currentAnchor,
				currentPeriod: state.currentPeriod,
				courses: state.courses,
				events: state.events,
				rubrica: state.rubrica,
				payments: state.payments,
				certificates: state.certificates,
				busy: state.busy,
				setAnchors,
				setCurrentAnchor,
				setCurrentPeriod,
				reset,
			}}
		>
			{children}
		</AnchorsContext.Provider>
	)
}
