import 'bootstrap/dist/css/bootstrap.min.css'
import './css/se-root.css'
import './css/lms-root.css'

// React
import { BrowserRouter as Router } from 'react-router-dom'
import { useContext, useState } from 'react'
import { useEffect } from 'react'

// vendor
import { ToastProvider } from 'react-toast-notifications'
import TopBarProgress from 'react-topbar-progress-indicator'
import SweetAlert from 'react-bootstrap-sweetalert'

// Components local
import { Default } from './components/Default'
import { UserProvider } from './context/shared/UserContext'
import { CourseProvider } from './context/CourseContext'

function App() {
	// Loader
	// Loader
	const [loadingBar, setLoadingBar] = useState(false)
	TopBarProgress.config({
		barColors: {
			0: '#1e7e34',
			'1.0': '#1e7e34',
		},
		barThickness: 5,
		shadowBlur: 5,
	})

	// SweetAlert
	// SweetAlert
	const [alert, setAlert] = useState(false)
	const alertHide = () => {
		setAlert(false)
	}

	// Effects
	// Effects
	useEffect(() => {
		document.title = 'LMS Instituto DIA'
	}, [])

	return (
		<UserProvider>
			<Router>
				<ToastProvider autoDismiss placement="bottom-left">
					<div>{loadingBar && <TopBarProgress />}</div>
					<CourseProvider>
						<Default setLoadingBar={setLoadingBar} setAlert={setAlert} />
					</CourseProvider>
				</ToastProvider>
				{alert && (
					<SweetAlert
						type={alert.type}
						title={alert.title}
						confirmBtnText={alert.confirmBtnText || 'OK'}
						confirmBtnBsStyle={alert.confirmBtnBsStyle || 'success'}
						cancelBtnText={alert.cancelBtnText || ''}
						cancelBtnBsStyle={alert.cancelBtnBsStyle || 'success'}
						onConfirm={alert.onConfirm || alertHide}
						onCancel={alert.onCancel || false}
						showCancel={alert.showCancel || false}
					>
						{alert.message}
					</SweetAlert>
				)}
			</Router>
		</UserProvider>
	)
}

export default App
