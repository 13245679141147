import http from './HttpCommon'

const upload = (userToken, courseId, file, onUploadProgress) => {
	let formData = new FormData()

	formData.append('file', file)
	formData.append('instruction', 'resource')
	formData.append('courseId', courseId)

	return http.post('/upload', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			DiaToken: userToken,
		},
		onUploadProgress,
	})
}

const getFiles = () => {
	return http.get('/files')
}

export default {
	upload,
	getFiles,
}
