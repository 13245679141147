import '../css/_CourseList.css'
import React, { useContext } from 'react'
import Table from 'react-bootstrap/esm/Table'
import Card from 'react-bootstrap/esm/Card'
import Alert from 'react-bootstrap/esm/Alert'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import { CourseContext } from '../context/CourseContext'

import { capitalizeFirstLetter } from '../utilities/String'

import moment from 'moment/min/moment-with-locales'
import { Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import Locator from '../components/Locator'
import { useEffect } from 'react'
import { UserContext } from '../context/shared/UserContext'
import { useToasts } from 'react-toast-notifications'

export default function CourseTable({ setLoadingBar, setAlert }) {
	const { addToast } = useToasts()
	const { courses, courseRemove, courseGetIndex } = useContext(CourseContext)
	const { userToken } = useContext(UserContext)
	const handleDelete = (course) => {
		setAlert({
			type: 'warning',
			title: 'Confirmación',
			message: 'Estas seguro de querer eliminar este curso? Se eliminarán todos los módulos, lecciones y recursos',
			confirmBtnText: 'Eliminar',
			cancelBtnText: 'Cancelar',
			cancelBtnBsStyle: 'warning',
			confirmBtnBsStyle: 'danger',
			showCancel: true,
			onCancel: () => {
				setAlert(false)
			},
			onConfirm: () => {
				setLoadingBar(true)
				courseRemove(userToken, course)
				setLoadingBar(false)
				setAlert(false)
				addToast('El curso ha sido eliminado', {
					appearance: 'success',
				})
			},
		})
	}
	useEffect(() => {
		setLoadingBar(true)
		courseGetIndex(userToken)
		setLoadingBar(false)
	}, [])
	return (
		<Card className="courseList-card">
			<Locator />
			<Table className="courseList-table" bordered striped responsive>
				<thead>
					<tr>
						<th className="col-md-4">Nombre del curso</th>
						<th className="col-md-2">Slug</th>
						{/* <th className="col-md-2">Fecha de registro</th> */}
						<th className="col-md-3">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{courses &&
						courses.map((course, index) => (
							<tr key={index}>
								<td>{course.title}</td>
								<td>{course.slug}</td>
								{/* <td>{capitalizeFirstLetter(moment(course.createdAt).locale('es').fromNow())}</td> */}
								<td className="courseList-actions">
									<Link to={`cursos/${course.id}/modulos`}>
										<Button className="course-btn-manage" variant="primary" size="sm">
											Administrar Contenido
										</Button>
									</Link>
									<Link to={`cursos/${course.id}/editar`}>
										<Button className="course-btn-edit" variant="info" size="sm">
											Editar
										</Button>
									</Link>
									<Button
										className="course-btn-delete"
										variant="danger"
										size="sm"
										onClick={() => {
											handleDelete(course)
										}}
									>
										Eliminar
									</Button>
								</td>
							</tr>
						))}
					{courses && courses.length === 0 && (
						<tr>
							<td colSpan={3} className="text-center">
								<Alert variant="warning" className="mt-2">
									<p>Por el momento no existen cursos registrados en el sistema</p>
									<Link to="cursos/agregar">
										<Button variant="success" className="course-btn-add">
											Agregar un curso
										</Button>
									</Link>
								</Alert>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<Row>
				<Col md={12} className="text-center">
					<Link to="cursos/agregar">
						<Button variant="success" className="course-btn-add">
							Agregar un curso
						</Button>
					</Link>
				</Col>
			</Row>
		</Card>
	)
}
