import React from 'react'
import Form from 'react-bootstrap/esm/Form'

export default function BlockTypeButton({ blockType, blockContent, setBlockContent, lastError, handleSetBlockContent }) {
	return (
		<>
			{blockType === 'button' && (
				<Form.Group className="mb-3">
					<Form.Label>Texto del botón</Form.Label>
					<Form.Control
						type="text"
						placeholder="Ejemplo: Descargar documento"
						onChange={(e) => {
							handleSetBlockContent({ text: e.target.value })
						}}
						value={blockContent.text}
						className={lastError === 'blockContent' && 'with-error'}
					/>
					<Form.Label>Link</Form.Label>
					<Form.Control
						type="text"
						placeholder="Ejemplo: https://www.google.com/"
						onChange={(e) => {
							handleSetBlockContent({ url: e.target.value })
						}}
						value={blockContent.url}
						className={lastError === 'blockContent' && 'with-error'}
					/>
				</Form.Group>
			)}
		</>
	)
}
