import '../css/_ContentManagement.css'
import React, { useContext, useEffect, useState } from 'react'
import Card from 'react-bootstrap/esm/Card'
import { CourseContext } from '../context/CourseContext'

import { Route, useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import ButtonBackToCoursesIndex from '../components/ButtonBackToCoursesIndex'
import PageTitle from '../components/PageTitle'

import ModuleManagement from './ModuleManagement'
import LessonManagement from './LessonManagement'
import BlockManagement from './BlockManagement'

export default function ContentManagement({ setLoadingBar, setAlert }) {
	const { courseId } = useParams() || false
	const { courses } = useContext(CourseContext)

	const [course, setCourse] = useState(false)
	const [modules, setModules] = useState([])

	useEffect(() => {
		console.log('ContentManagement.useEffect courseid, courses')
		if (courseId) {
			// eslint-disable-next-line eqeqeq
			const currentCourse = courses.find((course) => course.id == courseId)
			setCourse(currentCourse)
			setModules(currentCourse?.content?.modules || [])
		}
	}, [courseId])

	return (
		<Card className="courseList-card">
			<Route path={['/cursos/:courseId/modulos']} exact>
				<ModuleManagement modules={modules} setModules={setModules} setLoadingBar={setLoadingBar} setAlert={setAlert} />
			</Route>
			<Route path={['/cursos/:courseId/modulos/:moduleId/lecciones']} exact>
				<LessonManagement setLoadingBar={setLoadingBar} setAlert={setAlert} />
			</Route>
			<Route path={['/cursos/:courseId/modulos/:moduleId/lecciones/:lessonId/bloques']} exact>
				<BlockManagement setLoadingBar={setLoadingBar} setAlert={setAlert} />
			</Route>
			<ButtonBackToCoursesIndex />
		</Card>
	)
}
