import axios from 'axios'
import { platformBearerToken } from '../classes/Environment'

export default axios.create({
	baseURL: process.env.REACT_APP_DIA_LMS_API_LOCATION,
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${platformBearerToken}`,
	},
})
