import React from 'react'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Table from 'react-bootstrap/esm/Table'
import { ModuleRow } from './ModuleRow'
import Alert from 'react-bootstrap/esm/Alert'

export default function ModuleTable({ modules, setModules, moduleEdit, handleModuleDelete }) {
	const moveRow = (dragIndex, hoverIndex) => {
		const dragRecord = modules[dragIndex]
		setModules(
			update(modules, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRecord],
				],
			})
		)
	}
	return (
		<DndProvider backend={HTML5Backend}>
			<Table striped bordered responsive className="module-table">
				<thead>
					<tr>
						{/* <th className="col-md-1">Ordenar</th> */}
						<th className="col-md-1">Numeración</th>
						<th className="col-md-1">Tipo</th>
						<th className="col-md-6">Título del módulo</th>
						<th className="col-md-3">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{modules &&
						modules.length > 0 &&
						modules.map((module, index) => (
							<ModuleRow
								key={index}
								index={index}
								module={module}
								moveRow={moveRow}
								moduleEdit={moduleEdit}
								handleModuleDelete={handleModuleDelete}
							></ModuleRow>
						))}
					{!modules ||
						(modules.length === 0 && (
							<tr>
								<td colSpan={6} className="text-center">
									<Alert variant="warning" className="mt-2">
										Por el momento no existen módulos registrados en el curso
									</Alert>
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		</DndProvider>
	)
}
